import React from "react";
import { FunctionSubscriberContext } from "./FunctionSubscriberContext";

export interface FunctionSubscriberContextProps {
  // list of methods to expose from subscriber to consumer
  plexFetchDsApi: (searchName: string) => Promise<any>;
  plexFetchDsDetailApi: (id: string) => Promise<any>;
  plexShowOverlay: (status: boolean) => void;
  adhocRunFlow: (request: any) => Promise<any>;
  navigateToDataSourcePage: (data: any, openNewTab: boolean) => void;
  plexGetTraceEntries: (executionId: string, offset: number, limit: number) => Promise<any[]>;
  plexGetTraceSnapshots: (executionId: string, entryIndexes: number[]) => Promise<any>;
  plexSearchStandardObjects: (friendlyName: string) => Promise<any>;
  plexGetCustomFieldsSchema: (standardObjectId: string, standardObjectFriendlyName: string) => Promise<any>;
  children: React.ReactNode;
}

export const FunctionSubscriberProvider: React.FC<FunctionSubscriberContextProps> = ({
  plexFetchDsApi,
  plexFetchDsDetailApi,
  plexShowOverlay,
  adhocRunFlow,
  navigateToDataSourcePage,
  plexGetTraceEntries,
  plexGetTraceSnapshots,
  plexSearchStandardObjects,
  plexGetCustomFieldsSchema,
  children
}) => (
  <FunctionSubscriberContext.Provider
    value={{
      plexFetchDsApi,
      plexFetchDsDetailApi,
      plexShowOverlay,
      adhocRunFlow,
      navigateToDataSourcePage,
      plexGetTraceEntries,
      plexGetTraceSnapshots,
      plexSearchStandardObjects,
      plexGetCustomFieldsSchema
    }}
  >
    {children}
  </FunctionSubscriberContext.Provider>
);
