import React, { FunctionComponent } from "react";
import { IDataColumnDefProps, ITextQuery, PickerInput } from "@plex/react-components";
import { SourceAPI } from "../NodeTypes/TypeDefinitions";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import "./PickerFormField.scss";
import { usefunctionSubscriber } from "../FunctionSubscriberContext/FunctionSubscriberContext";
import { Edge, useReactFlow } from "reactflow";
import { canDeleteEdge } from "../Util/EdgeUtil";
import { useViewController } from "../ViewContext";
import { convertKeysToCamelCaseArray } from "../Util/KeyFormatter";

export const StandardObjectPickerFormField: FunctionComponent<INodeConfigPropertiesProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const viewController = useViewController();
  let [{ standardObjectPickerSelected, standardObjectPickerData, searchText }, setStandardObjectPicker] =
    React.useState({
      standardObjectPickerSelected: props.node?.data.nodeProperties[props.name]
        ? [
            {
              id: props.node?.data.nodeProperties[props.name],
              name: props.node?.data.nodeProperties.standardObjectName
            } as SourceAPI
          ]
        : ([] as SourceAPI[]),
      standardObjectPickerData: [] as { id: string; name: string }[],
      searchText: ""
    });
  const [, _setState] = React.useState({ value: props.node?.data.nodeProperties || {} });
  const [recordLimitExceededState, setRecordLimitExceededState] = React.useState(false);
  const [searchErrorState, setSearchErrorState] = React.useState("");
  const { plexSearchStandardObjects } = usefunctionSubscriber();

  const handlePickerSearch = (query: ITextQuery, cols: Array<IDataColumnDefProps<SourceAPI>>) => {
    if (plexSearchStandardObjects) {
      plexSearchStandardObjects(query?.displayText)
        .then((result: any) => {
          const resultData = convertKeysToCamelCaseArray(result.Data);
          const results = resultData.map((obj: any) => {
            return {
              id: obj.id,
              name: obj.friendlyName
            }
          });

          setRecordLimitExceededState(false);
          setSearchErrorState("");

          let standardObjects: {
            id: string;
            name: string;
          }[];
          if (cols.length === 0) {
            standardObjects = results.filter((x) =>
              (x.name || "").toLowerCase().includes(query.query.toLowerCase())
            );
          } else {
            standardObjects = results.filter((row: any) =>
              query.isMatch(cols.map((col) => (col.valueSelector ? String(col.valueSelector(row)) : "")))
            );
          }

          setStandardObjectPicker((x: any) => ({ ...x, standardObjectPickerData: standardObjects }));
        })
        .catch((e) => {
          setSearchErrorState("An error occurred when searching.");
          setRecordLimitExceededState(false);
          console.error(e);
        });
    }
  };

  const handlePickerSearchTextChanged = (value: string) => {
    setStandardObjectPicker((x: any) => ({ ...x, searchText: value }));
  };

  return (
    <PickerInput<SourceAPI>
      key="Standard Object Picker"
      dialogTitle="Standard Object"
      initialWidth={900}
      disabled={false}
      selected={standardObjectPickerSelected}
      onSelectionChanged={(values: any) => {
        const outputEdges = reactFlowInstance.getEdges().filter((e: Edge) => e.target === props.node.id);
        if (!outputEdges.every((e: Edge) => canDeleteEdge(e, reactFlowInstance, viewController.bannerContext))) {
          return;
        }

        if (values.length === 0) {
          setStandardObjectPicker((x) => ({ ...x, standardObjectPickerSelected: [], searchText: "" }));
          [];
          props.node!.data.nodeProperties[props.name] = null;
          props.node!.data.nodeProperties.standardObjectName = null;

          if (props.onChange) {
            props.onChange();
          }
          return;
        }

        if (props.node!.data.nodeProperties[props.name] === values[0]?.id) {
          return;
        }
        standardObjectPickerSelected = values.length > 0 ? [values[0]] : [];
        setStandardObjectPicker((x) => ({
          ...x,
          standardObjectPickerSelected: standardObjectPickerSelected,
          searchText: ""
        }));
        props.node!.data.nodeProperties[props.name] = values[0].id;
        props.node!.data.nodeProperties.standardObjectName = values[0].name;

        if (props.onChange) {
          props.onChange();
        }
      }}
      keySelector={(row: SourceAPI) => row?.id}
      displaySelector={(row: SourceAPI) => row?.name}
      data={standardObjectPickerData}
      onSearch={handlePickerSearch}
      searchDisabled={false}
      isOpen={false}
      multiSelect={false}
      maxDisplay={500}
      recordLimitExceeded={recordLimitExceededState}
      errorMessage={searchErrorState ? searchErrorState : undefined}
      searchText={searchText}
      onSearchTextChange={handlePickerSearchTextChanged}
    >
      <PickerInput.Column<SourceAPI>
        key="1"
        id="StandardObjectName"
        title="Name"
        valueSelector={(row: SourceAPI) => row.id}
      >
        {(row: SourceAPI) => row.name}
      </PickerInput.Column>
    </PickerInput>
  );
};
