import { Node, Edge, ReactFlowInstance, UpdateNodeInternals } from "reactflow";
import { AnchorProperties, handleAnchorDestinationLayoutChanged } from "./AnchorUtil";
import { IDesignerSchema, getSchema, getSchemaId } from "../NodeTypes/DataSchemas";
import { DocumentSchemaType, IDocumentListSchema, IDocumentObjectSchema } from "../FlowDocument/FlowDocumentModel";
import { IDataParam, IDataPropertyCountOptions, IDataTypeOptions, INodeProperty } from "../NodeTypes/Base";
import { BannerStatus } from "@plex/react-components";
import { NodeConfigPropertyType } from "../FlowDocument/PropertyTypeDefinitions";
import { canDeleteEdge, deleteEdgesFromNodes, updateLinkedSchemas } from "./EdgeUtil";
import { IDataTypeConfig } from "../AddNodeMenu/AddMenuNodePopulation";
import {
  DataType,
  convertToList,
  shouldConvertToList,
  dataTypes,
  isObjectLike,
  isListType
} from "../NodeTypes/TypeDefinitions";
import { addDataPropertiesFromCount } from "../NodePropertiesForm/DataPropertyCountFormField";
import { camelCaseToSpacesRegEx } from "../NodePropertiesForm/FormConstants";

const lastTimeoutId: { [nodeId: string]: any } = {};
const lastSecondaryTimeoutId: { [nodeId: string]: any } = {};

export const forceNodeUpdate = (
  node: Node<any> | undefined,
  updateNodeInternals: UpdateNodeInternals,
  reactFlowInstance: ReactFlowInstance,
  callback: (() => void) | undefined = undefined,
  timeout: number = 80
) => {
  let updateNodes: Node[] = [];

  if (!node) {
    return;
  }

  node.position.x += 0.0001;
  updateNodes.forEach((n) => (n.position.x += 0.0001));
  updateNodeInternals(node.id);
  if (lastTimeoutId[node.id] !== null) {
    clearTimeout(lastTimeoutId[node.id]);
  }
  lastTimeoutId[node.id] = setTimeout(() => {
    node.position.x -= 0.0001;
    updateNodes.forEach((n) => {
      n.position.x -= 0.0001;
      updateNodeInternals(n.id);
    });
    updateNodeInternals(node.id);
    lastTimeoutId[node.id] = null;

    if (node.data.nodeProperties.anchoredToNodeId || node.data.nodeProperties.anchoredNodes?.length > 0) {
      updateNodes = handleAnchorDestinationLayoutChanged(node.id, reactFlowInstance, updateNodeInternals);
      updateNodes.forEach((n) => {
        n.position.x += 0.0001;
        updateNodeInternals(n.id);
      });

      if (lastSecondaryTimeoutId[node.data.nodeProperties.anchoredToNodeId] !== null) {
        clearTimeout(lastTimeoutId[node.data.nodeProperties.anchoredToNodeId]);
      }
      lastSecondaryTimeoutId[node.data.nodeProperties.anchoredToNodeId] = setTimeout(() => {
        updateNodes.forEach((n) => {
          n.position.x -= 0.0001;
          updateNodeInternals(n.id);
        });
        lastSecondaryTimeoutId[node.data.nodeProperties.anchoredToNodeId] = null;
      }, timeout);

      if (node.data.nodeProperties.anchoredToNodeId) {
        forceNodeUpdate(
          reactFlowInstance.getNode(node.data.nodeProperties.anchoredToNodeId)!,
          updateNodeInternals,
          reactFlowInstance
        );
      }
    }

    if (callback) {
      callback();
    }
  }, timeout);
};

export const getNodeOutputs = (node: Node<any>) => {
  if (Object.keys(node!.data.nodeProperties.inputs).length > 0) {
    const nodeInput: IDataParam = node!.data.nodeProperties.inputs[Object.keys(node!.data.nodeProperties.inputs)[0]!];
    let inputSchema: IDesignerSchema = getSchema(nodeInput.schemaId!)!;
    if (inputSchema && inputSchema.schemaType === DocumentSchemaType.list) {
      inputSchema = getSchema((inputSchema as unknown as IDocumentListSchema).listItemSchema)!;
    }
    if (inputSchema && inputSchema.schemaType === DocumentSchemaType.object) {
      const schemaProperties = (inputSchema as unknown as IDocumentObjectSchema).properties;
      return Object.keys(schemaProperties).map((propertyName: string) => {
        const propertySchemaId: string = schemaProperties[propertyName]?.schema!;
        return {
          name: propertyName,
          type: propertySchemaId,
          schemaId: propertySchemaId,
          enabled: node.type === "callDataSource"
        } as IDataParam;
      });
    }
  }
  return [];
};

export const deleteNodes = (
  deletingNodes: Node[],
  updateNodeInternals: UpdateNodeInternals,
  reactFlowInstance: ReactFlowInstance,
  viewController: any
) => {
  if (deletingNodes.some((n) => n.data.nodeProperties.anchoredNodes?.length > 0)) {
    viewController.bannerContext.addMessage("Cannot delete, node must not be anchored.", BannerStatus.warning);
    return;
  }

  const deletingEdges = reactFlowInstance
    .getEdges()
    .filter((e: Edge) => deletingNodes.some((n: Node<any>) => n.id === e.source || n.id === e.target));
  if (!deletingEdges.every((e: Edge) => canDeleteEdge(e, reactFlowInstance, viewController.bannerContext))) {
    return;
  }

  let nodes = reactFlowInstance.getNodes();
  let deletingGroups = deletingNodes.filter((n) => n.type === "flowGroup");
  deletingGroups.forEach((g) =>
    nodes
      .filter((c) => c.data.nodeProperties.parentNode === g.id)
      .forEach((c) => {
        deletingNodes.push(c);
      })
  );

  // Need to first remove the node from anchored nodes list on the parent
  reactFlowInstance.setNodes((nds) => {
    nds.forEach((updatedNode: Node<any>) => {
      const anchoredDeletingNode = deletingNodes.find(
        (deletingNode: Node<any>) => deletingNode.data.nodeProperties.anchoredToNodeId === updatedNode.id
      );
      if (anchoredDeletingNode) {
        delete anchoredDeletingNode.data.nodeProperties.anchoredToNodeId;
        updatedNode.data.nodeProperties.anchoredNodes = updatedNode.data.nodeProperties.anchoredNodes.filter(
          (anchor: AnchorProperties) => anchor.nodeId !== anchoredDeletingNode.id
        );
      }
    });
    return nds;
  });

  setTimeout(() => {
    const newEdges = deleteEdgesFromNodes(
      deletingNodes.map((n: Node<any>) => n.id),
      reactFlowInstance,
      updateNodeInternals
    );
    updateLinkedSchemas(reactFlowInstance.getNodes(), newEdges, updateNodeInternals, reactFlowInstance);
    reactFlowInstance.setNodes((nds) => nds.filter((n) => !deletingNodes.some((dn) => n.id === dn.id)));
  }, 30);
};

export const canAddNode = (nodeType: string, reactFlowInstance: ReactFlowInstance) => {
  if (nodeType === "start" && reactFlowInstance.getNodes().some((n) => n.data.nodeProperties.type === "start")) {
    return false;
  }

  return true;
};

export const updateNodeDataProperties = (node: Node<any>) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!)!;
  const dataTypeConfigs = nodeDefinition.nodeConfigProperties.filter(
    (p: INodeProperty) => p.propertyType === NodeConfigPropertyType.DataType
  );
  dataTypeConfigs.forEach((dataTypeConfig: INodeProperty) => {
    if (!node!.data.nodeProperties[dataTypeConfig.name]) {
      return;
    }

    const selectingFromListTypes = ((dataTypeConfig.options as IDataTypeOptions)?.dataTypes ?? dataTypes).some(
      (t: string) => isListType(t as DataType)
    );

    const dataTypeOptions = dataTypeConfig.options as IDataTypeOptions;
    node!.data.nodeProperties.isNowValue = false;
    dataTypeOptions.dataProperties.forEach((dataPropertyName: string) => {
      const inputDataProperty = node!.data.nodeProperties.inputs[dataPropertyName];
      const outputDataProperty = node!.data.nodeProperties.outputs[dataPropertyName];
      const configDataType: string = node!.data.nodeProperties[dataTypeConfig.name];
      if (inputDataProperty) {
        const isObject = isObjectLike(configDataType as DataType);
        let dataType = configDataType as DataType;
        if (shouldConvertToList(dataType, inputDataProperty, selectingFromListTypes)) {
          dataType = convertToList(dataType);
        }
        node!.data.nodeProperties.inputs[dataPropertyName] = {
          ...inputDataProperty,
          type: dataType,
          schemaId: isObject ? "string" : getSchemaId(dataType)
        };
      }
      if (outputDataProperty) {
        const isObject = isObjectLike(configDataType as DataType);
        let dataType = configDataType as DataType;
        if (shouldConvertToList(dataType, outputDataProperty, selectingFromListTypes)) {
          dataType = convertToList(dataType);
        }
        node!.data.nodeProperties.outputs[dataPropertyName] = {
          ...outputDataProperty,
          type: dataType,
          schemaId: isObject ? "string" : getSchemaId(dataType)
        };
      }

      const propertyCountConfig = nodeDefinition?.nodeConfigProperties.filter(
        (property: INodeProperty) => (property.options as IDataPropertyCountOptions)?.cloneFrom === dataPropertyName
      )[0];
      if (propertyCountConfig) {
        addDataPropertiesFromCount(
          node,
          propertyCountConfig.name,
          propertyCountConfig.options as IDataPropertyCountOptions
        );
      }
    });
  });
};

export const getAvailableConfigDataTypes = (nodeType: string, dataPropertyName: string) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(nodeType)!;
  let availableDataTypes: IDataTypeConfig[] = [];

  // Get properties linked to this one, since property count clones data properties we may need to look at the clone source.
  const linkedPropertyNames: string[] = [dataPropertyName];
  const linkedConfigs = nodeDefinition.nodeConfigProperties
    .filter((config: INodeProperty) => config.propertyType === NodeConfigPropertyType.DataPropertyCount)
    .filter(
      (config: INodeProperty) =>
        dataPropertyName.indexOf((config.options as IDataPropertyCountOptions)?.cloneFrom) === 0
    );
  linkedConfigs.forEach((config: INodeProperty) =>
    linkedPropertyNames.push((config.options as IDataPropertyCountOptions)?.cloneFrom)
  );
  availableDataTypes = availableDataTypes.concat(
    nodeDefinition.dataOutputs
      .filter((dataOutput: IDataParam) =>
        linkedConfigs.some((nodeProperty: INodeProperty) => nodeProperty.name === dataOutput.name)
      )
      .map((dataOutput: IDataParam) => {
        return { dataType: dataOutput.type as string } as IDataTypeConfig;
      })
  );
  const dynamicTypeProperties = nodeDefinition.nodeConfigProperties
    .filter((p: INodeProperty) => p.propertyType === NodeConfigPropertyType.DataType)
    .map((property: INodeProperty) => property) as INodeProperty[];
  const dynamicTypeProperty = dynamicTypeProperties.filter((dataTypeProperty: INodeProperty) =>
    linkedPropertyNames.some(
      (linkedName: string) =>
        (dataTypeProperty?.options as IDataTypeOptions)?.dataProperties.some(
          (dataPropertyName: string) => dataPropertyName === linkedName
        )
    )
  )[0];
  const dynamicTypeOptions = dynamicTypeProperty?.options as IDataTypeOptions;
  if (dynamicTypeProperty) {
    const propertyTypes: IDataTypeConfig[] = (dynamicTypeOptions.dataTypes ?? dataTypes).map((d: DataType) => {
      return {
        configProperty: { propertyName: dynamicTypeProperty.name, propertyValue: d },
        dataType: d
      };
    });
    availableDataTypes = availableDataTypes
      .filter(
        (config: IDataTypeConfig) =>
          !propertyTypes.some((propertyTypeConfig: IDataTypeConfig) => propertyTypeConfig.dataType === config.dataType)
      )
      .concat(propertyTypes);
  }

  // If no types were added from dynamic types, add types directly from inputs/outputs
  if (availableDataTypes.length === 0) {
    availableDataTypes = availableDataTypes.concat(
      nodeDefinition.dataInputs
        .filter((dataInput: IDataParam) => dataInput.name === dataPropertyName)
        .map((dataInput: IDataParam) => {
          return { dataType: dataInput.type as string } as IDataTypeConfig;
        })
    );
    availableDataTypes = availableDataTypes.concat(
      nodeDefinition.dataOutputs
        .filter((dataOutput: IDataParam) => dataOutput.name === dataPropertyName)
        .map((dataOutput: IDataParam) => {
          return { dataType: dataOutput.type as string } as IDataTypeConfig;
        })
    );
  }

  return availableDataTypes;
};

export const camelCaseToReadable = (camelCase: string) => {
  const readableName = camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
  return readableName.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
};

export const setNodeSelection = (selectedNodeIds: string[], reactFlowInstance: ReactFlowInstance) => {
  const nodes = reactFlowInstance.getNodes();
  const currentSelectedNodes = nodes.filter((n) => n.selected);
  currentSelectedNodes.forEach((n) => (n.selected = false));
  nodes.filter((n) => selectedNodeIds.includes(n.id)).forEach((n) => (n.selected = true));
  reactFlowInstance.setNodes(nodes);
};
