import { IBannerContext, BannerStatus } from "@plex/react-components";
import { IControlFlowParam } from "../NodeTypes/Base";
import { Node, Edge, UpdateNodeInternals } from "reactflow";
import { camelCaseToSpacesRegEx } from "../NodePropertiesForm/FormConstants";

export interface IFlowRunResponse {
  missingRequiredInput: { node: Node; inputName: string }[];
  output: any;
}

export interface IFlowOrderError {
  fromNode: Node;
  toNode: Node;
}

export interface IFlowRunRequest {
  input: any;
  nodes: Node[];
  edges: Edge[];
  runtimeNodeDelayMs: number;
  bannerContext?: IBannerContext;
  updateNodeInternals?: UpdateNodeInternals;
  validateOnly?: boolean;
  apiReadUrl?: string;
  apiModifyUrl?: string;
  dsApiUrl?: string;
  credentials?: {
    openApiToken: string;
    customerId: string;
    dsApiUserName: string;
    dsApiToken: string;
  };
}

const timeout = (ms: number) => {
  if (ms === 0) {
    return new Promise<void>((resolve) => resolve());
  }
  return new Promise((resolve) => setTimeout(resolve, ms));
};

// const triggerErrorHandleHighlight = (nodeId: string, handleName: string, status: boolean) => {
//   const nodeDom = document.getElementById(nodeId);

//   if (nodeDom === null) {
//     return null;
//   }

//   if (status) {
//     nodeDom.querySelector(`[data-handleid="${handleName}"]`)?.classList.add("blink");
//   } else {
//     const errorControlNodes = nodeDom?.querySelectorAll(`[data-handleid*="${handleName}"]`);
//     if (errorControlNodes.length > 0) {
//       errorControlNodes.forEach((x) => {
//         x.classList.remove("blink");
//       });
//     }
//   }
// };

let loopIterationStartCallback: () => void = () => {};
let loopIterationStopCallback: () => void = () => {};
let loopBreakCallback: () => void = () => {};

export const loopIterationStart = () => loopIterationStartCallback();
export const loopIterationStop = () => loopIterationStopCallback();
export const loopBreak = () => loopBreakCallback();

export interface IRuntimeDataInput {
  targetNode: Node;
  inputName: string;
  outputName: string;
}

export interface IRuntimeDataOutput {
  sourceNode: Node;
  inputName: string;
  outputName: string;
}

export const getDataInputs = (nodeId: string, nodes: Node[], edges: Edge[]): IRuntimeDataInput[] => {
  const dataInputs: IRuntimeDataInput[] = [];
  const inputDataEdges = edges.filter(
    (e) =>
      e.source === nodeId &&
      e.sourceHandle?.indexOf("FlowInHandle") !== 0 &&
      e.sourceHandle?.indexOf("FlowInGroup") !== 0 &&
      !(e.sourceHandle?.split("-").length === 4 && e.sourceHandle?.split("-")[0] === "internal")
  );

  for (let i = 0; i < inputDataEdges.length; i++) {
    let inputEdge: any = inputDataEdges[i];
    let inputIdParts = inputEdge.sourceHandle!.split("-");
    let inputName = inputEdge.sourceHandle!.replace(
      "-" + inputIdParts[inputIdParts.length - 2] + "-" + inputIdParts[inputIdParts.length - 1],
      ""
    );
    let outputIdParts = inputEdge.targetHandle!.split("-");
    let outputName: any = "";
    if (outputIdParts.length === 4) {
      outputName = inputEdge
        .targetHandle!.replace(
          "-" + outputIdParts[outputIdParts.length - 2] + "-" + outputIdParts[outputIdParts.length - 1],
          ""
        )
        .replace(outputIdParts[outputIdParts.length - 4] + "-", "");
    } else {
      outputName = inputEdge.targetHandle!.replace(
        "-" + outputIdParts[outputIdParts.length - 2] + "-" + outputIdParts[outputIdParts.length - 1],
        ""
      );
    }
    let targetNode: any = nodes.filter((n) => n.id === inputEdge.target)[0];

    // Remap input to node in group that connects to the group output.
    let groupNode: Node | null = null;
    if (
      targetNode.type === "flowGroup" &&
      inputEdge.targetHandle?.split("-").length === 4 &&
      inputEdge.targetHandle?.split("-")[0] === "external"
    ) {
      groupNode = targetNode;
      let groupEdge: any = edges.filter(
        (e) => e.source === groupNode!.id && e.sourceHandle!.split("-")[1] === outputName
      )[0];
      if (groupEdge) {
        targetNode = nodes.filter((n) => n.id === groupEdge.target)[0];
        outputName = groupEdge.targetHandle!.split("-")[0];
      }
    }

    dataInputs.push({ targetNode: targetNode, inputName: inputName, outputName: outputName });
  }

  return dataInputs;
};

export const getDataOutputs = (nodeId: string, nodes: Node[], edges: Edge[]): IRuntimeDataOutput[] => {
  const dataOutputs: IRuntimeDataOutput[] = [];
  const outputDataEdges = edges.filter(
    (e) =>
      e.target === nodeId &&
      e.targetHandle?.indexOf("FlowOutHandle") !== 0 &&
      e.targetHandle?.indexOf("FlowOutGroup") !== 0 &&
      !(e.targetHandle?.split("-").length === 4 && e.targetHandle?.split("-")[0] === "internal")
  );

  for (let i = 0; i < outputDataEdges.length; i++) {
    let outputEdge: any = outputDataEdges[i];
    let outputIdParts = outputEdge.sourceHandle!.split("-");
    let outputName = outputEdge.targetHandle!.replace(
      "-" + outputIdParts[outputIdParts.length - 2] + "-" + outputIdParts[outputIdParts.length - 1],
      ""
    );
    let inputIdParts = outputEdge.targetHandle!.split("-");
    let inputName: any = "";
    if (inputIdParts.length === 4) {
      inputName = outputEdge
        .sourceHandle!.replace(
          "-" + inputIdParts[inputIdParts.length - 2] + "-" + inputIdParts[inputIdParts.length - 1],
          ""
        )
        .replace(inputIdParts[inputIdParts.length - 4] + "-", "");
    } else {
      inputName = outputEdge.sourceHandle!.replace(
        "-" + inputIdParts[inputIdParts.length - 2] + "-" + inputIdParts[inputIdParts.length - 1],
        ""
      );
    }
    let sourceNode: any = nodes.filter((n) => n.id === outputEdge.source)[0];

    // Remap input to node in group that connects to the group output.
    let groupNode: Node | null = null;
    if (
      sourceNode.type === "flowGroup" &&
      outputEdge.sourceHandle?.split("-").length === 4 &&
      outputEdge.sourceHandle?.split("-")[0] === "external"
    ) {
      groupNode = sourceNode;
      let groupEdge: any = edges.filter(
        (e) => e.target === groupNode!.id && e.targetHandle!.split("-")[1] === inputName
      )[0];
      if (groupEdge) {
        sourceNode = nodes.filter((n) => n.id === groupEdge.source)[0];
        inputName = groupEdge.sourceHandle!.split("-")[0];
      }
    }

    dataOutputs.push({ sourceNode: sourceNode, inputName: inputName, outputName: outputName });
  }

  return dataOutputs;
};

export const getControlInputEdges = (nodeId: string, edges: Edge[]) => {
  return edges.filter(
    (e) =>
      e.source === nodeId &&
      (e.sourceHandle?.indexOf("FlowInHandle") === 0 || e.sourceHandle === "FlowInGroupExternalHandle")
  );
};

export const getControlOutputEdges = (nodeId: string, nodeType: string, nodes: Node[], edges: Edge[]) => {
  const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(nodeType)!;
  const controlOutputEdges: (Edge & { originalTargetHandle: string; startsLoop: boolean })[] = [];
  let outputFlowEdges = edges.filter(
    (e) =>
      e.target === nodeId &&
      (e.targetHandle?.indexOf("FlowOutHandle") === 0 || e.targetHandle === "FlowOutGroupInternalHandle")
  );

  for (let i = 0; i < outputFlowEdges.length; i++) {
    const outputFlowEdge: any = outputFlowEdges[i];
    const controlOutputName = outputFlowEdge.targetHandle.replace("FlowOutHandle", "");
    const outputStartsLoop =
      nodeDefinition.controlOutputs.filter((output: IControlFlowParam) => output.name === controlOutputName)[0]
        ?.startsLoop ?? false;
    const sourceNode: any = nodes.filter((n) => n.id === outputFlowEdge.source)[0];
    if (sourceNode.type === "flowGroup") {
      if (outputFlowEdge.sourceHandle === "FlowInGroupInternalHandle") {
        let nextFlowEdge = edges.filter(
          (e) => e.target === sourceNode.id && e.targetHandle === "FlowOutGroupExternalHandle"
        )[0];
        controlOutputEdges.push({
          ...nextFlowEdge,
          originalTargetHandle: outputFlowEdge.targetHandle!,
          startsLoop: outputStartsLoop,
          id: "",
          source: "",
          target: ""
        });
      }
      if (outputFlowEdge.sourceHandle === "FlowInGroupExternalHandle") {
        let nextFlowEdge = edges.filter(
          (e) => e.target === sourceNode.id && e.targetHandle === "FlowOutGroupInternalHandle"
        )[0];
        controlOutputEdges.push({
          ...nextFlowEdge,
          originalTargetHandle: outputFlowEdge.targetHandle!,
          startsLoop: outputStartsLoop,
          id: "",
          source: "",
          target: ""
        });
      }
    } else {
      controlOutputEdges.push({
        ...outputFlowEdge,
        originalTargetHandle: outputFlowEdge.targetHandle!,
        startsLoop: outputStartsLoop
      });
    }
  }

  return controlOutputEdges;
};

export const simulateFlow = (request: IFlowRunRequest): Promise<IFlowRunResponse> => {
  //const apiOrigin = "";
  // const apiOrigin = window.__NEXT_DATA__.props.pageProps.flowApiOrigin;
  let visitedNodeOutputs: any = {};
  let runOutput: any = {};
  let currentLoopNodeId: string | null = null;
  let currentLoopIterationNodes: Node[] = [];
  let currentLoopFromNode: Node | null;
  let currentLoopStartFlowOrder: number;
  // let controlOrders: { [nodeId: string]: { order: number; visitedNodes: Node[] } } = {};
  let controlOrders: any = {};
  // let dataOrders: { [nodeId: string]: { order: number; visitedNodes: Node[] } } = {};
  let dataOrders: any = {};
  // let flowOrderErrors: { fromNode: Node; toNode: Node }[] = [];
  let missingRequiredInput: { node: Node; inputName: string }[] = [];
  let startNodeFilter = request.nodes.filter((n) => n.type === "start");
  let systemError: boolean = false;
  //const { credentials: creds } = request;
  if (startNodeFilter.length === 0) {
    request.bannerContext?.addMessage("Start node is required to run the flow.", BannerStatus.error);
    return new Promise<IFlowRunResponse>((resolve) =>
      resolve({
        missingRequiredInput: [],
        output: {}
      })
    );
  }
  let startNode: any = startNodeFilter[0];

  if (request.validateOnly === undefined) {
    request.validateOnly = false;
  }

  // let apiReadUrl = request.apiReadUrl ?? apiOrigin + "/openApiRead/0";
  // let apiModifyUrl = request.apiModifyUrl ?? apiOrigin + "/openApiModify/0";
  // let dsApiUrl = request.dsApiUrl ?? apiOrigin + "/dsApi/execute/0";

  const resetVisit = (node: Node) => {
    delete visitedNodeOutputs[node.id];
    delete controlOrders[node.id];
    delete dataOrders[node.id];
    delete node.data.nodeProperties.testInput;
    delete node.data.nodeProperties.testOutput;
  };

  const getHandleDisplayName = (name: string) => {
    name = name.charAt(0).toUpperCase() + name.slice(1);
    name = name.replace(camelCaseToSpacesRegEx, "$1$3 $2$4$5");
    name = name.replaceAll("_", " ");
    return name;
  };

  const visitNode = (
    fromNode: Node | null,
    node: Node,
    flowOrder: number,
    isControlFlow: boolean,
    fromControlFlow: boolean,
    direction: number
  ): Promise<any> => {
    const displayVisitCompleted = () => {
      if (node.data.nodeProperties.isEvaluating) {
        node.data.nodeProperties.isEvaluating = false;
        node.position.x -= 0.0001;
        request.updateNodeInternals && request.updateNodeInternals(node.id);
      }

      node.data.nodeProperties.testOutput = outputData;
    };

    if (systemError) {
      return new Promise<void>((resolve) => resolve());
    }

    if (visitedNodeOutputs[node.id]) {
      return Promise.resolve(visitedNodeOutputs[node.id]);
    }

    if (isControlFlow) {
      controlOrders[node.id] = {
        order: flowOrder,
        visitedNodes: fromNode ? controlOrders[fromNode.id].visitedNodes.concat(fromNode) : []
      };
      node.data.nodeProperties.controlOrder = flowOrder;
      node.data.nodeProperties.dataOrder = 0;
    } else {
      dataOrders[node.id] = {
        order: flowOrder,
        visitedNodes: fromNode && dataOrders[fromNode.id] ? dataOrders[fromNode.id].visitedNodes.concat(fromNode) : []
      };
      node.data.nodeProperties.controlOrder = fromNode?.data.nodeProperties.controlOrder;
      node.data.nodeProperties.dataOrder = fromNode?.data.nodeProperties.dataOrder + direction;
    }

    let dataInputs = getDataInputs(node.id, request.nodes, request.edges);
    let outputFlowEdges = getControlOutputEdges(node.id, node.type!, request.nodes, request.edges);
    let inputData: any = {};
    let outputData: any = {};

    let visitPromise: Promise<any> = Promise.resolve(outputData);
    let inputLoopPromise = Promise.resolve();
    for (let i = 0; i < dataInputs.length; i++) {
      const dataInput: any = dataInputs[i];
      inputLoopPromise = inputLoopPromise.then(() =>
        visitNode(node, dataInput.targetNode, flowOrder - 1, false, isControlFlow, -1).then((visitResult: any) => {
          if (visitResult === null || systemError) {
            return new Promise<void>((resolve) => resolve());
          }
          inputData[dataInput.inputName] = visitResult[dataInput.outputName];

          if (dataInput.targetNode.type === "flowGroup") {
            if (!dataInput.targetNode!.data.nodeProperties.testGroupOutput) {
              dataInput.targetNode!.data.nodeProperties.testGroupOutput = {};
            }
            dataInput.targetNode!.data.nodeProperties.testGroupOutput[dataInput.inputName] =
              visitResult[dataInput.outputName];
          }
        })
      );
    }

    inputLoopPromise = inputLoopPromise.then(() => {
      if (!request.validateOnly && node.type === "switch" && node.data.nodeProperties.switchType === "Branch") {
        let activeBranchHandle = evaluateBranch(inputData[node.data.nodeProperties.inputs[0].name], node);
        outputFlowEdges = outputFlowEdges.filter((e) => e.targetHandle === activeBranchHandle);
      }

      if (!request.validateOnly && request.updateNodeInternals) {
        node.data.nodeProperties.testInput = inputData;

        if (node.data.nodeProperties.parentNode) {
          let group: any = request.nodes.filter((n) => n.id === node.data.nodeProperties.parentNode)[0];
          if (group.data.nodeProperties.isCollapsed) {
            if (!group.data.nodeProperties.isEvaluating) {
              group.data.nodeProperties.isEvaluating = true;
              group.position.x += 0.0001;
              request.updateNodeInternals(group.id);
            }
          }
        }

        if (!node.data.nodeProperties.isEvaluating) {
          node.data.nodeProperties.isEvaluating = true;
          node.position.x += 0.0001;
          request.updateNodeInternals(node.id);
        }
      }

      let errorOccurred = false;

      switch (node.type) {
        case "value":
          if (node.data.nodeProperties.isNowValue) {
            outputData.value = new Date().toUTCString();
          } else {
            outputData.value = getValueWithType(
              node.data.nodeProperties.valueType[0].value,
              node.data.nodeProperties.value
            );
          }
          break;
        case "math":
          outputData.output = evaluateMath(inputData, node);
          break;
        case "switch":
          if (node.data.nodeProperties.switchType === "Coalesce") {
            let foundOutput = false;
            node.data.nodeProperties.inputs.forEach((input: any) => {
              let inputValue = inputData[input.name];
              if (
                !foundOutput &&
                inputValue !== undefined &&
                inputValue !== null &&
                (!(typeof inputValue === "string" || inputValue instanceof String) || inputValue.trim() !== "")
              ) {
                outputData[node.data.nodeProperties.outputs[0].name] = getValueWithType(input.type, inputValue);
                foundOutput = true;
              }
            });
          }
          break;
        case "plexOpenApi":
          // case "callDataSource":
          //   if (request.validateOnly) {
          //     Object.keys(node.data.nodeProperties.inputs).forEach((inputName) => {
          //       if (
          //         node.data.nodeProperties.inputs[inputName].required &&
          //         (inputData[inputName] === undefined || inputData[inputName] === "null" || inputData[inputName] === "")
          //       ) {
          //         missingRequiredInput.push({ node: node, inputName: inputName });
          //       }
          //     });
          //     Object.keys(node.data.nodeProperties.outputs).forEach((outputName) => {
          //       if (node.data.nodeProperties.outputs[outputName].enabled === true) {
          //         outputData[outputName] = outputName;
          //       }
          //     });

          //     const errorFlowhandleEdges = request.edges.filter(
          //       (e) => e.target === node.id && e.targetHandle?.indexOf("FlowErrorHandle") === 0
          //     );
          //     triggerErrorHandleHighlight(node.id, "FlowErrorHandle_", false);
          //     if (errorFlowhandleEdges.length > 0) {
          //       let mappedErrorEdges = errorFlowhandleEdges.map((e) => {
          //         return { ...e, originalTargetHandle: e.targetHandle!, startsLoop: false };
          //       });
          //       outputFlowEdges = outputFlowEdges.concat(mappedErrorEdges);
          //     }
          //   }

          // TODO: Remove, or will we keep the client runtime?

          // } else {
          //   if (node.data.nodeProperties.isDataSourceApi) {
          //     inputData.dsApiId = node.data.nodeProperties.dataSourceId;
          //     let headers: any = {
          //       Accept: "application/json",
          //       "Content-Type": "application/json",
          //       "DS-API-User-Name": creds?.dsApiUserName,
          //       "DS-API-Token": creds?.dsApiToken
          //     };

          //     try {
          //       const response = await fetch(dsApiUrl, {
          //         method: "POST",
          //         headers: headers,
          //         body: JSON.stringify(inputData)
          //       });

          //       if (!response.ok) {
          //         throw new Error("Network response was not ok.");
          //       }

          //       let result = await response.json();

          //       if (result.errors && result.errors.length > 0) {
          //         errorOccurred = true;
          //       }

          //       outputData.rows = result.rows;
          //       outputData.output = result.outputs;
          //     } catch (error) {
          //       request.bannerContext?.addMessage("An unexpected error occurred", BannerStatus.error);
          //       node.data.nodeProperties.isEvaluating = false;
          //       systemError = true;
          //     }
          //   } else {
          //     const apiPath = node.data.nodeProperties.apiPath;

          //     if (node.data.nodeProperties.apiType === "read") {
          //       let requestUrl = apiReadUrl + "?apiRoute=" + apiPath.replaceAll("/", "`");
          //       let bracketInput: any = Object.keys(inputData).filter(
          //         (inputName) => requestUrl.indexOf("{" + inputName + "}") !== -1
          //       )[0];
          //       if (
          //         Object.keys(inputData).filter((inputName) => inputName !== bracketInput && inputData[inputName])
          //           .length > 0
          //       ) {
          //         requestUrl += "%3F"; // ?
          //         requestUrl += Object.keys(inputData)
          //           .map((inputName: string) => {
          //             return inputName + "=" + inputData[inputName];
          //           })
          //           .join("%26"); // &
          //       }
          //       requestUrl = requestUrl.replace("{" + bracketInput + "}", inputData[bracketInput]);
          //       console.log(requestUrl);
          //       let headers: any = {
          //         "X-Plex-Connect-Api-Key": creds?.openApiToken,
          //         "X-Plex-Connect-Customer-Id": creds?.customerId
          //       };

          //       try {
          //         const response = await fetch(requestUrl, {
          //           method: "GET",
          //           headers: headers,
          //           cache: "no-store"
          //         });

          //         if (!response.ok) {
          //           throw new Error("Network response was not ok.");
          //         }

          //         let result = await response.json();

          //         // If API Fails and iterate and visit underlying nodes
          //         if (
          //           node.data.nodeProperties?.errorTypes?.length > 0 &&
          //           response.status.toString().indexOf("40") === 0
          //         ) {
          //           const handleName = `FlowErrorHandle_${response.status.toString()}`;
          //           triggerErrorHandleHighlight(node.id, handleName, true);
          //           outputFlowEdges = outputFlowEdges.filter(
          //             (e) => e.target === node.id && e.targetHandle?.indexOf(handleName) === 0
          //           );
          //         }

          //         if (result.errors?.length > 0) {
          //           if (Object.keys(node.data.nodeProperties.outputs).length > 1) {
          //             const outPutKey: any = Object.keys(node.data.nodeProperties.outputs)[1];
          //             outputData[outPutKey] = result;
          //           }
          //         } else {
          //           const outPutKey: any = Object.keys(node.data.nodeProperties.outputs)[0];
          //           outputData[outPutKey] = result;
          //         }
          //       } catch (error) {
          //         request.bannerContext?.addMessage("An unexpected error occurred", BannerStatus.error);
          //         node.data.nodeProperties.isEvaluating = false;
          //         systemError = true;
          //       }
          //     } else {
          //       let requestUrl = apiModifyUrl;
          //       inputData.apiRoute = apiPath.replaceAll("/", "`");
          //       let bracketInput: any = Object.keys(inputData).filter(
          //         (inputName) => inputData.apiRoute.indexOf("{" + inputName + "}") !== -1
          //       )[0];
          //       inputData.apiRoute = inputData.apiRoute.replace("{" + bracketInput + "}", inputData[bracketInput]);
          //       inputData.apiMethod = node.data.nodeProperties.apiMethod;
          //       console.log(inputData.apiMethod);
          //       console.log(requestUrl);
          //       let headers: any = {
          //         Accept: "application/json",
          //         "Content-Type": "application/json",
          //         "X-Plex-Connect-Api-Key": creds?.openApiToken,
          //         "X-Plex-Connect-Customer-Id": creds?.customerId
          //       };

          //       try {
          //         const response = await fetch(requestUrl, {
          //           method: "POST",
          //           headers: headers,
          //           body: JSON.stringify(inputData)
          //         });

          //         if (!response.ok) {
          //           throw new Error("Network response was not ok.");
          //         }

          //         let result = await response.json();

          //         // If API Fails and iterate and visit underlying nodes
          //         if (
          //           (node.data.nodeProperties?.errorTypes?.length > 0 &&
          //             response.status.toString().indexOf("40") === 0) ||
          //           !response.ok
          //         ) {
          //           const handleName = `FlowErrorHandle_${response.status.toString()}`;

          //           triggerErrorHandleHighlight(node.id, handleName, true);

          //           outputFlowEdges = outputFlowEdges.filter(
          //             (e) => e.target === node.id && e.targetHandle?.indexOf(handleName) === 0
          //           );
          //         }

          //         // If outputflow edges doesn't have items then we should mark errorOccurred as true since we want to do visit nodes that falls in error control nodes
          //         if (result.errors && result.errors.length > 0 && outputFlowEdges.length === 0) {
          //           errorOccurred = true;
          //         }

          //         if (result.errors?.length > 0) {
          //           if (Object.keys(node.data.nodeProperties.outputs).length > 1) {
          //             const outPutKey: any = Object.keys(node.data.nodeProperties.outputs)[1];
          //             outputData[outPutKey] = result;
          //           }
          //         } else {
          //           const outPutKey: any = Object.keys(node.data.nodeProperties.outputs)[0];
          //           outputData[outPutKey] = result;
          //         }
          //       } catch (error) {
          //         request.bannerContext?.addMessage("An unexpected error occurred", BannerStatus.error);
          //         node.data.nodeProperties.isEvaluating = false;
          //         systemError = true;
          //       }
          //     }
          //   }
          // }
          break;
        case "flowGroup":
          node.data.nodeProperties.groupInput.forEach((input: any) => {
            outputData[input.name] = inputData[input.name];
          });
          break;
      }

      loopIterationStartCallback = () => {
        currentLoopNodeId = node.id;
        currentLoopIterationNodes.push(node);
        currentLoopFromNode = fromNode;
        currentLoopStartFlowOrder = flowOrder;
      };

      loopIterationStopCallback = () => {
        currentLoopIterationNodes.forEach((n) => resetVisit(n));
        currentLoopIterationNodes = [];
      };

      loopBreakCallback = () => {
        currentLoopIterationNodes.forEach((n) => resetVisit(n));
        currentLoopIterationNodes = [];
        const requestNodeBreakLoop: any = request.nodes.filter((n: any) => n.id === currentLoopNodeId)[0];
        requestNodeBreakLoop.data.nodeProperties.breakLoop = true;
      };

      if (!request.validateOnly && currentLoopNodeId) {
        currentLoopIterationNodes.push(node);
      }

      let nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node.type!);
      if (nodeDefinition) {
        if (!request.validateOnly) {
          if (!nodeDefinition.skipEvaluate) {
            try {
              let evaluateResult = nodeDefinition.evaluate(inputData, node.data.nodeProperties);
              outputData = evaluateResult.output;
              outputFlowEdges = outputFlowEdges.filter(
                (e) =>
                  evaluateResult.activeControlHandleIds.filter((h) => "FlowOutHandle" + h === e.originalTargetHandle)
                    .length > 0
              );
            } catch (error) {
              request.bannerContext?.addMessage("An unexpected error occurred", BannerStatus.error);
              node.data.nodeProperties.isEvaluating = false;
              systemError = true;
            }
          }
        } else {
          let inputs = node.data.nodeProperties.inputs;
          if (node.type === "flowGroup") {
            inputs = node.data.nodeProperties.groupInput;
          }
          Object.keys(inputs).forEach((inputName) => {
            if (inputs[inputName].required && (inputData[inputName] === undefined || inputData[inputName] === "null")) {
              const name = inputs?.[inputName]?.label ?? getHandleDisplayName(inputName);
              missingRequiredInput.push({ node: node, inputName: name });
            }
          });
          let outputs = node.data.nodeProperties.outputs;
          if (node.type === "flowGroup") {
            outputs = node.data.nodeProperties.groupOutput;
          }
          Object.keys(outputs).forEach((outputName) => {
            if (outputs[outputName].enabled === true) {
              outputData[outputName] = outputName;
            }
          });

          let controlHandles = nodeDefinition.controlOutputs.map((c) => {
            const id = "FlowOutHandle" + c.name;
            const name = c?.label ?? getHandleDisplayName(c.name);
            return { id, name };
          });

          if (
            nodeDefinition.controlInputOnly ||
            (!nodeDefinition.controlOutputOnly && nodeDefinition.controlInputs.length > 0)
          ) {
            const controlInput = nodeDefinition.controlInputs[0];
            if (controlInput) {
              const id = "FlowInHandle" + controlInput.name;
              const name = controlInput?.label ?? getHandleDisplayName(controlInput.name);
              controlHandles = [...controlHandles, { id, name }];
            }
          }

          controlHandles.forEach((handle) => {
            let isConnected =
              request.edges.filter(
                (edge) =>
                  (edge.source === node.id && edge.sourceHandle === handle.id) ||
                  (edge.target === node.id && edge.targetHandle === handle.id)
              ).length > 0;
            if (!isConnected) {
              missingRequiredInput.push({ node: node, inputName: handle.name });
            }
          });
        }
      }

      if (!request.validateOnly && request.updateNodeInternals) {
        if (node.data.nodeProperties.parentNode) {
          let group: any = request.nodes.filter((n) => n.id === node.data.nodeProperties.parentNode)[0];
          if (group.data.nodeProperties.isCollapsed) {
            //await timeout(request.runtimeNodeDelayMs);
            if (group.data.nodeProperties.isEvaluating) {
              group.data.nodeProperties.isEvaluating = false;
              group.position.x -= 0.0001;
              request.updateNodeInternals && request.updateNodeInternals(group.id);
            }
          }
        }

        visitPromise = timeout(request.runtimeNodeDelayMs).then(() => {
          displayVisitCompleted();
          return Promise.resolve(outputData);
        });
      }

      if (errorOccurred || systemError) {
        return new Promise<void>((resolve) => resolve());
      }

      !request.validateOnly && console.log(node.type);
      if (node.data.nodeProperties?.apiAction) {
        !request.validateOnly && console.log(node.data.nodeProperties?.apiAction[0].value);
      }

      !request.validateOnly && console.log(inputData);
      !request.validateOnly && console.log(outputData);

      if (node.type !== "forEachNext") {
        visitedNodeOutputs[node.id] = outputData;
      }

      if (node.type === "stop") {
        runOutput = inputData;
      }

      if ((node.type === "forEachNext" || node.type === "forEachBreak") && !request.validateOnly) {
        let loopNodeId = currentLoopNodeId;
        const loopId: any = request.nodes.filter((n) => n.id === loopNodeId)[0];
        currentLoopNodeId = null;
        visitPromise = timeout(request.runtimeNodeDelayMs).then(() => {
          displayVisitCompleted();
          return visitNode(currentLoopFromNode, loopId, currentLoopStartFlowOrder, true, true, -1);
        });
      } else {
        for (let i = 0; i < outputFlowEdges.length; i++) {
          let outputFlowEdge: any = outputFlowEdges[i];
          let sourceNode = request.nodes.filter((n) => n.id === outputFlowEdge.source)[0];
          if (sourceNode) {
            visitPromise = timeout(request.runtimeNodeDelayMs).then(() => {
              displayVisitCompleted();
              return visitNode(node, sourceNode!, flowOrder + 1, true, isControlFlow, 1);
            });
          }
        }
      }
    });

    return inputLoopPromise.then(() => visitPromise);
  };

  !request.validateOnly && console.log("Flow run started.");
  request.nodes.forEach((n) => {
    delete n.data.nodeProperties.testInput;
    delete n.data.nodeProperties.testOutput;
    delete n.data.nodeProperties.testGroupOutput;
    delete n.data.nodeProperties.breakLoop;
    delete n.data.nodeProperties.itemIndex;
  });
  return visitNode(null, startNode, 0, true, true, 1).then(() => {
    !request.validateOnly && console.log("Flow run complete.");
    return Promise.resolve({ output: runOutput, missingRequiredInput: missingRequiredInput });
  });
};

const getValueWithType = (type: string, value: string) => {
  switch (type) {
    case "integer":
      return parseInt(value);
    case "decimal":
      return parseFloat(value);
    case "boolean":
      return value ? true : false;
    case "date":
      return value.toString();
    default:
      return value;
  }
};

const evaluateMath = (inputData: any, mathNode: Node) => {
  let expression = mathNode.data.nodeProperties.outputs[0].expressions[0];

  if (isNaN(inputData[expression.leftInputName]) || isNaN(inputData[expression.rightInputName])) {
    console.log("Math input is not a number.");
    return 0;
  }

  switch (expression.operator) {
    case "+":
      return inputData[expression.leftInputName] + inputData[expression.rightInputName];
    case "-":
      return inputData[expression.leftInputName] - inputData[expression.rightInputName];
    case "*":
      return inputData[expression.leftInputName] * inputData[expression.rightInputName];
    case "/":
      return inputData[expression.leftInputName] / inputData[expression.rightInputName];
  }

  return 0;
};

const evaluateBranch = (inputValue: any, node: Node) => {
  for (let i = 0; i < node.data.nodeProperties.outputs.length; i++) {
    let output = node.data.nodeProperties.outputs[i];

    let allConditionsMet = true;
    for (let j = 0; j < output.conditions.length; j++) {
      let conditionConfig = output.conditions[j];

      if (inputValue === undefined || inputValue === null) {
        inputValue = "";
      }

      let conditionMet = false;
      switch (conditionConfig.condition) {
        case "==":
          conditionMet = inputValue == conditionConfig.outputValue;
          break;
        case "!=":
          conditionMet = inputValue != conditionConfig.outputValue;
          break;
        case ">":
          conditionMet = inputValue > conditionConfig.outputValue;
          break;
        case "<":
          conditionMet = inputValue < conditionConfig.outputValue;
          break;
        case ">=":
          conditionMet = inputValue >= conditionConfig.outputValue;
          break;
        case "<=":
          conditionMet = inputValue <= conditionConfig.outputValue;
          break;
      }
      allConditionsMet = allConditionsMet && conditionMet;
    }

    if (allConditionsMet) {
      return "FlowOutHandle" + output.uniqueId;
    }
  }
  return "";
};
