import React, { ChangeEvent, FunctionComponent, useEffect, useState } from "react";
import { Form, Button } from "@plex/react-components";
import { Node, useReactFlow, useUpdateNodeInternals } from "reactflow";
import "./TestPropertiesForm.scss";
import {
  IExecutionError,
  INodeTrace,
  getReplayState,
  playReplay,
  updateBreakpointNodeId,
  setReplayPaused,
  stepBack,
  stepForward,
  skipToFirst,
  skipToLast,
  stopReplay,
  latestTraceMatchesNodeReplayIo,
  displaySelectedNodeSnapshotData
} from "../Runtime/RuntimePlayback";
import { useViewController } from "../ViewContext";
import { setNodeSelection } from "../Util/NodeUtil";
import { usefunctionSubscriber } from "../FunctionSubscriberContext/FunctionSubscriberContext";

export interface ITestPropertiesForm {
  testSettings: ITestProperties;
  setTestPropertiesState: React.Dispatch<React.SetStateAction<ITestProperties>>;
}

export interface IReplay {
  nodeTraces: INodeTrace[];
  traceIndex: number;
  isPaused: boolean;
  isStopped: boolean;
  isStopping: boolean;
  breakPointNodeId?: string;
  executionId?: string;
}

export interface ITestProperties {
  node?: Node;
  runtimeNodeDelayMs: number;
  isValid: boolean;
  errors: IExecutionError[];
  replay: IReplay;
  executionTime: number;
  nodeTraceCount: number;
}

export const TestPropertiesForm: FunctionComponent<ITestPropertiesForm> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const viewController = useViewController();
  const functionSubscriber = usefunctionSubscriber();
  const replayState = getReplayState();

  const createSettingsSection = () => {
    const [tooltipVisible, setTooltipVisible] = useState(false);

    const handleNodeDelayChange = (e: ChangeEvent<HTMLInputElement>) => {
      let value = parseFloat(e.currentTarget.value);
      if (!value) {
        value = 25;
      }
      props.setTestPropertiesState({ ...props.testSettings, runtimeNodeDelayMs: value });
      globalThis.testPropertiesState = { ...props.testSettings, runtimeNodeDelayMs: value };

      setTooltipVisible(true);

      return e;
    };

    const handleMouseUp = () => {
      setTooltipVisible(false);
    };

    useEffect(() => {
      window.addEventListener("mouseup", handleMouseUp);
      return () => {
        window.removeEventListener("mouseup", handleMouseUp);
      };
    }, []);

    return (
      <Form.Section className={props.testSettings.node ? "" : "plex-fd-no-selection-form-section"} title="Settings">
        {props.testSettings.replay.executionId && (
          <Form.Row>
            <Form.FieldPair labelText="Execution Time" className="plex-fd-execution-time-field-pair">
              <div>{Number(props.testSettings.executionTime.toFixed(2))} sec</div>
            </Form.FieldPair>
          </Form.Row>
        )}

        <Form.Row>
          <Form.FieldPair labelText="Node Delay" className="node-delay-field-pair">
            <div className="plex-fd-slider-container">
              <input
                name="RuntimeNodeDelayMs"
                value={props.testSettings.runtimeNodeDelayMs}
                type="range"
                min={25}
                max={1000}
                step={25}
                onChange={handleNodeDelayChange}
                onMouseDown={() => setTooltipVisible(true)}
                id="delaySlider"
              />

              {tooltipVisible && (
                <div
                  className="plex-fd-replay-slider-tooltip"
                  style={{
                    left: `calc(${(props.testSettings.runtimeNodeDelayMs / 1000) * 100}%)`
                  }}
                >
                  {props.testSettings.runtimeNodeDelayMs} ms
                </div>
              )}
            </div>
          </Form.FieldPair>
        </Form.Row>
      </Form.Section>
    );
  };

  const showErrorsSection = () => {
    return props.testSettings.errors.length > 0;
  };

  const createErrorsSection = () => {
    return (
      <Form.Section className={props.testSettings.node ? "" : "plex-fd-no-selection-form-section"} title="Errors">
        {props.testSettings.errors.length === 0 ? (
          <div />
        ) : (
          props.testSettings.errors.map((error: IExecutionError, errorIndex: number) => {
            return (
              <Form.Row key={"run-error-display-" + errorIndex}>
                <div onClick={() => onErrorClick(error)} className="plex-fd-validation-error-message">
                  {error.message}
                </div>
              </Form.Row>
            );
          })
        )}
      </Form.Section>
    );
  };

  const onErrorClick = (error: IExecutionError) => {
    if (error.nodeId) {
      const viewport = reactFlowInstance.getViewport();
      reactFlowInstance.fitView({
        nodes: [{ id: error.nodeId }],
        minZoom: viewport.zoom,
        maxZoom: viewport.zoom,
        duration: 500
      });
      setNodeSelection([error.nodeId], reactFlowInstance);
    }
  };

  const getTestInput = () => {
    if (!replayState.isStopped && !latestTraceMatchesNodeReplayIo(props.testSettings.node!)) {
      displaySelectedNodeSnapshotData(
        props.testSettings.node!,
        functionSubscriber,
        reactFlowInstance,
        updateNodeInternals
      );
      return "Loading...";
    }

    return JSON.stringify(props.testSettings.node!.data.nodeProperties.testInput, null, 2);
  };

  const getTestOutput = () => {
    if (!replayState.isStopped && !latestTraceMatchesNodeReplayIo(props.testSettings.node!)) {
      return "Loading...";
    }

    let output = props.testSettings.node!.data.nodeProperties.testOutput;

    if (props.testSettings.node!.type === "flowGroup") {
      output = props.testSettings.node!.data.nodeProperties.testGroupOutput;
    }

    return JSON.stringify(output, null, 2);
  };

  const noTraces = replayState.nodeTraces.length === 0;
  const isStopped = replayState.isStopped;
  const isPaused = replayState.isPaused;
  const breakpointActiveNodeId = replayState.breakPointNodeId;
  const replayControlButtonSize = "xs";

  return (
    <Form className="plex-fd-trace-form">
      <div className="plex-fd-test-log-container">
        {createSettingsSection()}
        {showErrorsSection() ? createErrorsSection() : <div />}

        <div className="plex-fd-replay-controls-container">
          <Button
            key="first-replay-button"
            className={`flow-replay-control ${isStopped ? "btn-first-disabled" : "btn-first-enabled"}`}
            size={replayControlButtonSize}
            disabled={isStopped}
            title="First"
            onClick={() => skipToFirst(reactFlowInstance, updateNodeInternals, viewController.bannerContext)}
          />

          <Button
            key="previous-replay-button"
            className={`flow-replay-control ${
              !isPaused || isStopped ? "btn-previous-disabled" : "btn-previous-enabled"
            }`}
            size={replayControlButtonSize}
            disabled={!isPaused || isStopped}
            title="Previous"
            onClick={() =>
              stepBack(reactFlowInstance, updateNodeInternals, viewController.bannerContext, functionSubscriber)
            }
          />

          <Button
            key="play-pause-replay-button"
            className={`flow-replay-control
              ${noTraces ? "btn-play-disabled" : ""}
              ${noTraces || !(isPaused || isStopped) ? "" : "btn-play-enabled"}
              ${noTraces || isPaused || isStopped ? "" : "btn-pause-enabled"}
              `}
            size={replayControlButtonSize}
            title={noTraces ? "Play" : isPaused ? "Play" : "Pause"}
            onClick={() => {
              if (isPaused) {
                Promise.resolve()
                  .then(() => {
                    if (!isStopped) {
                      stepForward(
                        reactFlowInstance,
                        updateNodeInternals,
                        viewController.bannerContext,
                        functionSubscriber
                      );
                    }
                  })
                  .then(() => {
                    setReplayPaused(false);
                    playReplay(
                      reactFlowInstance,
                      updateNodeInternals,
                      viewController.bannerContext,
                      functionSubscriber
                    );
                  });
              } else {
                setReplayPaused(true);
              }
            }}
            disabled={noTraces}
          />

          <Button
            key="stop-replay-button"
            className={`flow-replay-control ${noTraces || isStopped ? "btn-stop-disabled" : "btn-stop-enabled"}`}
            size={replayControlButtonSize}
            disabled={noTraces || isStopped}
            title="Stop"
            onClick={() => stopReplay(reactFlowInstance)}
          />

          <Button
            key="next-replay-button"
            className={`flow-replay-control ${!isPaused || isStopped ? "btn-next-disabled" : "btn-next-enabled"}`}
            size={replayControlButtonSize}
            disabled={!isPaused || isStopped}
            title="Next"
            onClick={() =>
              stepForward(reactFlowInstance, updateNodeInternals, viewController.bannerContext, functionSubscriber)
            }
          />

          <Button
            key="last-replay-button"
            className={`flow-replay-control ${isStopped ? "btn-last-disabled" : "btn-last-enabled"}`}
            size={replayControlButtonSize}
            disabled={isStopped}
            title="Last"
            onClick={() =>
              skipToLast(reactFlowInstance, updateNodeInternals, viewController.bannerContext, functionSubscriber)
            }
          />

          <Button
            key="breakpoint-replay-button"
            className={`flow-replay-control ${
              isStopped || !props.testSettings.node?.selected
                ? "btn-breakpoint-disabled"
                : breakpointActiveNodeId
                ? "btn-breakpoint-pressed"
                : "btn-breakpoint-enabled"
            }`}
            size={replayControlButtonSize}
            disabled={isStopped || !props.testSettings.node?.selected}
            title="Stop At"
            onClick={() => updateBreakpointNodeId(reactFlowInstance, updateNodeInternals)}
          />
        </div>

        {props.testSettings.node ? (
          <>
            <Form.Section className="plex-fd-node-input-output-log-section" title="Input">
              <pre className="plex-fd-test-log-section">{getTestInput()}</pre>
            </Form.Section>
            <Form.Section title="Output">
              <pre className="plex-fd-test-log-section">{getTestOutput()}</pre>
            </Form.Section>
          </>
        ) : (
          <></>
        )}
      </div>
    </Form>
  );
};
