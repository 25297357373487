import { Edge, Node, ReactFlowInstance, UpdateNodeInternals, getRectOfNodes } from "reactflow";
import { EdgeDetail } from "./EdgeUtil";
import { forceNodeUpdate } from "./NodeUtil";
import { DataType, isListType } from "../NodeTypes/TypeDefinitions";

export enum AnchorArea {
  Input,
  Output,
  Bottom,
  Top
}

export interface AnchorProperties {
  nodeId: string;
  isArray: boolean;
  area: AnchorArea;
  propertyName: string;
  propertyType: string;
}

const getIntersectingNodes = (node: Node<any>, reactFlowInstance: ReactFlowInstance): Node<any>[] => {
  return reactFlowInstance
    .getNodes()
    .filter(
      (n) => n.id !== node.id && reactFlowInstance.isNodeIntersecting(getRectOfNodes([n]), getRectOfNodes([node]))
    );
};

export const handleAnchorDestinationLayoutChanged = (
  destinationNodeId: string,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals
) => {
  let parentNode: Node | undefined = undefined;
  let checkingParentNode: Node | undefined = reactFlowInstance.getNode(destinationNodeId);
  while (checkingParentNode) {
    parentNode = checkingParentNode;
    if (checkingParentNode.data.nodeProperties.anchoredToNodeId) {
      checkingParentNode = reactFlowInstance.getNode(checkingParentNode.data.nodeProperties.anchoredToNodeId);
    } else {
      checkingParentNode = undefined;
    }
  }

  const updatedNodes = updateAnchorLayout(parentNode!.id, reactFlowInstance, updateNodeInternals, 0);
  reactFlowInstance.setNodes((nodes) =>
    nodes
      .filter((n: Node<any>) => updatedNodes.filter((un: Node<any>) => un.id === n.id).length === 0)
      .concat(updatedNodes)
  );

  return updatedNodes;
};

const updateAnchorLayout = (
  destinationNodeId: string,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  depth: number
) => {
  const destinationNode = reactFlowInstance.getNode(destinationNodeId)!;
  let updatedNodes: Node[] = [];
  if (destinationNode.data.nodeProperties.anchoredNodes) {
    for (let i = 0; i < destinationNode.data.nodeProperties.anchoredNodes.length; i++) {
      const anchorProperties = destinationNode.data.nodeProperties.anchoredNodes[i];
      const anchorPosition = getAnchorPosition(anchorProperties, destinationNode, reactFlowInstance);
      const anchoredNode = reactFlowInstance.getNode(anchorProperties.nodeId)!;

      anchoredNode.zIndex = 1002 + depth * 1000 + depth;
      anchoredNode.position.x = anchorPosition.x;
      anchoredNode.position.y = anchorPosition.y;
      anchoredNode.data.nodeProperties.lastPosition.x = anchorPosition.x;
      anchoredNode.data.nodeProperties.lastPosition.y = anchorPosition.y;
      updateNodeInternals(anchoredNode.id);
      updatedNodes.push(anchoredNode);

      updatedNodes = updatedNodes.concat(
        updateAnchorLayout(anchoredNode.id, reactFlowInstance, updateNodeInternals, depth + 1)
      );
    }
  }

  return updatedNodes;
};

export const handleAnchorNodeDrop = (
  node: Node<any>,
  edges: Edge<any>[],
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  skipNodeUpdates = false,
  presetDestinationNode?: Node<any>
) => {
  let didAnchor = false;
  const intersections = getIntersectingNodes(node, reactFlowInstance);
  const nodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(node.type!);
  const nodeEdges: Edge<any>[] = edges.filter((e: Edge<any>) => e.source === node.id || e.target === node.id);
  const destinationNode = presetDestinationNode
    ? presetDestinationNode
    : intersections.filter(
        (n: Node<any>) =>
          n.id !== node.id &&
          n.data.nodeProperties.anchoredToNodeId !== node.id &&
          (nodeDefinition.unconnectedAnchorLocation ||
            nodeEdges.filter((e) => e.target === n.id || e.source === n.id).length > 0)
      )[0];

  if (!node.data.nodeProperties.anchoredToNodeId && destinationNode) {
    const anchorProperties = getAnchorProperties(node, destinationNode, reactFlowInstance);
    if (anchorProperties.nodeCanBeAnchored && node.data.nodeProperties.anchoredToNodeId !== destinationNode?.id) {
      if (node.data.nodeProperties.anchoredToNodeId) {
        const oldDestination = reactFlowInstance.getNode(node.data.nodeProperties.anchoredToNodeId);
        if (oldDestination) {
          oldDestination!.data.nodeProperties.anchoredNodes = oldDestination?.data.nodeProperties.anchoredNodes.filter(
            (anchoredNode: AnchorProperties) => anchoredNode.nodeId !== node.id
          );
        }
      }

      if (destinationNode) {
        if (!destinationNode.data.nodeProperties.anchoredNodes) {
          destinationNode.data.nodeProperties.anchoredNodes = [];
        }

        const newAnchorProperties = {
          nodeId: node.id,
          isArray: anchorProperties.isArray,
          area: anchorProperties.anchorArea,
          propertyName: anchorProperties.anchorPropertyName,
          propertyType: anchorProperties.propertyType
        };
        destinationNode.data.nodeProperties.anchoredNodes.push(newAnchorProperties);

        node.data.nodeProperties.anchoredToNodeId = destinationNode?.id;
        didAnchor = true;
        const anchorPosition = getAnchorPosition(newAnchorProperties, destinationNode, reactFlowInstance);
        node.data.nodeProperties.anchorDepth = (destinationNode.data.nodeProperties.anchorDepth ?? 0) + 1;
        node.zIndex = 1002 + node.data.nodeProperties.anchorDepth * 1000 + node.data.nodeProperties.anchorDepth;
        node.position.x = anchorPosition.x;
        node.position.y = anchorPosition.y;
        node.data.nodeProperties.lastPosition = { x: anchorPosition.x, y: anchorPosition.y };
        reactFlowInstance.setNodes((nodes: Node<any>[]) => nodes.filter((n) => n.id !== node.id).concat(node));

        const targetNodeId = newAnchorProperties.area === AnchorArea.Output ? destinationNode.id : node.id;
        const sourceNodeId = newAnchorProperties.area === AnchorArea.Input ? destinationNode.id : node.id;
        const anchoredEdge = edges.filter((e) => e.target === targetNodeId && e.source === sourceNodeId)[0];
        if (anchoredEdge) {
          anchoredEdge.className += " hidden-anchored-edge";
          edges = edges.filter((e) => e.id !== anchoredEdge.id).concat(anchoredEdge);
        }

        if (!skipNodeUpdates) {
          // Need to update twice, due to the sizing changes when a node is anchored.
          setTimeout(() => forceNodeUpdate(destinationNode, updateNodeInternals, reactFlowInstance), 80);
        }
      }
    }
  }

  if (!didAnchor) {
    // Refine positioning of anchored nodes
    setTimeout(() => {
      const selectedNodes = reactFlowInstance.getNodes().filter((n: Node<any>) => n.selected);
      selectedNodes.forEach((n: Node<any>) =>
        handleAnchorDestinationLayoutChanged(n.id, reactFlowInstance, updateNodeInternals)
      );
    }, 80);
  }

  return edges;
};

export const getAnchorPosition = (
  anchorProperties: AnchorProperties,
  destinationNode: Node<any>,
  reactFlowInstance: ReactFlowInstance
) => {
  const node = reactFlowInstance.getNode(anchorProperties.nodeId)!;
  const labelContainerHeight = destinationNode.data.nodeProperties.labelContainerHeight;
  let paddingTop = 14;
  const bubbleHeight = 17.5;
  let extraPadding = 3;
  const chainSpacing = 10;
  let anchorWidthOffset = 0;
  let anchorHeightOffset = 0;

  let enabledProperties: any = [];
  let dataProperties: any;

  if (anchorProperties.area === AnchorArea.Input) {
    dataProperties = destinationNode.data.nodeProperties.inputs;
  } else if (anchorProperties.area === AnchorArea.Output) {
    extraPadding = 8;
    dataProperties = destinationNode.data.nodeProperties.outputs;
  }

  if (dataProperties) {
    enabledProperties = Object.keys(dataProperties).filter((propertyName) => dataProperties[propertyName].enabled);
  }

  if (destinationNode.data.nodeProperties.anchoredToNodeId) {
    paddingTop = 10;
    const nodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(node.type!);
    const destinationNodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(destinationNode.type!);
    if (nodeDefinition.hideLabelOnAnchor) {
      if (anchorProperties.area === AnchorArea.Output) {
        extraPadding = 12;
      }
    }
    if (destinationNodeDefinition.hideLabelOnAnchor) {
      extraPadding -= 4;
    }
  }
  for (let i = 0; i < enabledProperties.length; i++) {
    const property = enabledProperties[i];
    let isChainedProperty = false;
    const chain = getChain(destinationNode.id, reactFlowInstance);
    const chainIndex = chain.findIndex((n) => n.id === destinationNode.id);
    if (
      destinationNode.data.nodeProperties.anchoredToNodeId &&
      chain.length > 1 &&
      i === 0 &&
      chainIndex < chain.length - 1
    ) {
      isChainedProperty = true;
    }

    if (
      (!isChainedProperty || anchorProperties.area === AnchorArea.Output) &&
      (property !== anchorProperties.propertyName || anchorProperties.area === AnchorArea.Output)
    ) {
      if (!isChainedProperty) {
        anchorHeightOffset += bubbleHeight;
      }

      if (isChainedProperty) {
        if (!isChainHead(node.id, reactFlowInstance)) {
          anchorHeightOffset += chainSpacing;
        }
      }
    }

    if (property === anchorProperties.propertyName) {
      const anchoredPropertyNode = destinationNode.data.nodeProperties.anchoredNodes.filter(
        (anchorProperties: AnchorProperties) => anchorProperties.propertyName === property
      )[0];
      if (anchoredPropertyNode) {
        const anchoredNode = reactFlowInstance.getNode(anchoredPropertyNode.nodeId)!;
        anchorWidthOffset = (destinationNode.width ?? 0) - (anchoredNode.width ?? 0);
      }
      break;
    } else {
      const anchoredPropertyNode = destinationNode.data.nodeProperties.anchoredNodes.filter(
        (anchorProperties: AnchorProperties) => anchorProperties.propertyName === property
      )[0];
      if (anchoredPropertyNode) {
        const anchoredNode = reactFlowInstance.getNode(anchoredPropertyNode.nodeId)!;
        if (isChainHead(anchoredNode.id, reactFlowInstance)) {
          const chain = getChain(anchoredNode.id, reactFlowInstance);
          chain.forEach((node: Node, index) => {
            anchorHeightOffset +=
              (node.height ?? 0) + (chain.length > 1 && index < chain.length - 1 ? chainSpacing : 0);
          });
        }
      }
    }
  }

  const nodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(node.type!);
  // Not anchored by any input or output
  if (nodeDefinition.unconnectedAnchorLocation) {
    if (nodeDefinition.unconnectedAnchorLocation === AnchorArea.Bottom) {
      anchorHeightOffset += 10;
    }

    const unconnectedAnchors: AnchorProperties[] = destinationNode.data.nodeProperties.anchoredNodes?.filter(
      (anchorProperties: AnchorProperties) => {
        const unconnectedAnchorNodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(
          reactFlowInstance.getNode(anchorProperties.nodeId)?.type!
        );
        return unconnectedAnchorNodeDefinition.unconnectedAnchorLocation === nodeDefinition.unconnectedAnchorLocation;
      }
    );

    if (nodeDefinition.unconnectedAnchorLocation === AnchorArea.Top) {
      anchorWidthOffset = 10;
    }

    if (nodeDefinition.unconnectedAnchorLocation === AnchorArea.Bottom) {
      anchorHeightOffset += destinationNode.data.nodeProperties.inputOutputHeight;
      anchorWidthOffset = 10;
    }

    for (let i = 0; i < unconnectedAnchors.length; i++) {
      const unconnectedAnchorProperties: any = unconnectedAnchors[i];
      if (unconnectedAnchorProperties.nodeId === node.id) {
        break;
      }
      const anchoredNode = reactFlowInstance.getNode(unconnectedAnchorProperties.nodeId)!;
      anchorHeightOffset += (anchoredNode.height ?? 0) + 10;
    }

    if (nodeDefinition.unconnectedAnchorLocation === AnchorArea.Output) {
      anchorWidthOffset = (destinationNode.width ?? 0) - (node.width ?? 0);
    }
  }

  if (anchorProperties.area === AnchorArea.Input) {
    anchorHeightOffset += destinationNode.data.nodeProperties.outputHeight;
    anchorHeightOffset -= getAnchorDepth(node.id, reactFlowInstance) * 1.5;
  }

  if (anchorProperties.area === AnchorArea.Input) {
    let positionY = destinationNode.position.y + labelContainerHeight + paddingTop + anchorHeightOffset + extraPadding;
    if (!isChainHead(node.id, reactFlowInstance)) {
      positionY = destinationNode.position.y - (node.height ?? 0);
      positionY -= chainSpacing;
    } else {
      const chain = getChain(node.id, reactFlowInstance);
      chain
        .filter((n) => n.id !== destinationNode.id && n.id !== node.id)
        .forEach((node: Node, index) => {
          positionY += (node.height ?? 0) + (index < chain.length - 1 ? chainSpacing : 0);
        });
    }
    return {
      x: destinationNode.position.x + 0,
      y: positionY
    };
  } else {
    let positionY =
      destinationNode.position.y +
      (anchorProperties.area === AnchorArea.Top ? 0 : labelContainerHeight) +
      paddingTop +
      anchorHeightOffset +
      extraPadding -
      2;
    if (!isChainHead(node.id, reactFlowInstance)) {
      positionY = destinationNode.position.y + (destinationNode.height ?? 0);
      positionY += chainSpacing;
    }
    return {
      x: destinationNode.position.x + anchorWidthOffset,
      y: positionY
    };
  }
};

const getAnchorProperties = (node: Node<any>, destinationNode: Node<any>, reactFlowInstance: ReactFlowInstance) => {
  const nodeDefinition: any = globalThis.nodeTypeDefinitions.getDefinition(node.type!);
  const hasControl =
    nodeDefinition.controlOutputs.length > 0 ||
    nodeDefinition.controlOutputOnly ||
    nodeDefinition.controlInputs.length > 0;
  let nodeCanBeAnchored = true;
  let anchorArea = AnchorArea.Top;
  let anchorPropertyName = "";
  let isArray = false;
  let propertyType = "";

  if (hasControl) {
    nodeCanBeAnchored = false;
  }

  if (
    node.data.nodeProperties.parentNode === destinationNode.id ||
    destinationNode.data.nodeProperties.parentNode === node.id
  ) {
    nodeCanBeAnchored = false;
  }

  const dataInputs = node.data.nodeProperties.inputs;
  const connectedDataInputs: any = Object.keys(dataInputs ?? {}).filter(
    (inputName: string) =>
      reactFlowInstance
        .getEdges()
        .filter(
          (e) =>
            e.source === node.id &&
            e.target === destinationNode.id &&
            new EdgeDetail(node.id, reactFlowInstance).getInput(inputName)
        ).length > 0
  );

  const dataOutputs = node.data.nodeProperties.outputs;
  const connectedDataOutputs: any = Object.keys(dataOutputs ?? {}).filter(
    (outputName: string) =>
      reactFlowInstance
        .getEdges()
        .filter(
          (e) =>
            e.target === node.id &&
            e.source === destinationNode.id &&
            new EdgeDetail(node.id, reactFlowInstance).getOutput(outputName)
        ).length > 0
  );

  if (connectedDataInputs.length > 0) {
    anchorArea = AnchorArea.Output;
    const input = new EdgeDetail(node.id, reactFlowInstance).getInput(connectedDataInputs[0]);
    isArray = isListType(input?.targetHandle.type as DataType);
    propertyType = input?.targetHandle.type!;
    anchorPropertyName = input?.targetHandle.name ?? "";
    if (Object.keys(dataInputs).length > 1) {
      nodeCanBeAnchored = false;
    }
  }

  if (connectedDataOutputs.length > 0) {
    anchorArea = AnchorArea.Input;
    const output = new EdgeDetail(node.id, reactFlowInstance).getOutput(connectedDataOutputs[0]);
    isArray = isListType(output?.sourceHandle.type as DataType);
    propertyType = output?.sourceHandle.type!;
    anchorPropertyName = output?.sourceHandle.name ?? "";
    if (Object.keys(dataOutputs).length > 1) {
      nodeCanBeAnchored = false;
    }
  }

  if (nodeDefinition.unconnectedAnchorLocation) {
    anchorArea = nodeDefinition.unconnectedAnchorLocation;
  }

  if (
    Object.keys(dataInputs ?? {}).length > 0 &&
    connectedDataInputs.length === 0 &&
    Object.keys(dataOutputs ?? {}).length > 0 &&
    connectedDataOutputs.length === 0
  ) {
    nodeCanBeAnchored = false;
  }

  return {
    isArray: isArray,
    nodeCanBeAnchored: nodeCanBeAnchored,
    anchorArea: anchorArea,
    anchorPropertyName: anchorPropertyName,
    propertyType: propertyType
  };
};

export const handleAnchorDrag = (node: Node, reactFlowInstance: ReactFlowInstance) => {
  const selectedNodes = reactFlowInstance
    .getNodes()
    .filter((n: Node<any>) => n.selected && n.id !== node.id)
    .concat([node]);
  let firstOfAnchoredNodes: Node<any>[] = [node];
  if (selectedNodes.length > 1) {
    // Need the top level anchored node, so that anchor updates aren't also called for nested anchors.
    firstOfAnchoredNodes = selectedNodes.filter((n: Node<any>) => {
      const anchorGroup = getAllAnchoredNodes(n.id, reactFlowInstance).sort(
        (a: Node<any>, b: Node<any>) =>
          getAnchorDepth(a.id, reactFlowInstance) - getAnchorDepth(b.id, reactFlowInstance)
      );
      const firstSelectedInAnchorGroup = anchorGroup.find((chainedNode: Node<any>) =>
        selectedNodes.some((selectedNode: Node<any>) => chainedNode.id === selectedNode.id)
      );
      return firstSelectedInAnchorGroup?.id === n.id;
    });
  }
  firstOfAnchoredNodes.forEach((n: Node<any>) => handleAnchorDragSingleNode(n, reactFlowInstance));
  return firstOfAnchoredNodes;
};

const handleAnchorDragSingleNode = (node: Node, reactFlowInstance: ReactFlowInstance) => {
  let anchoredNodes = getAllAnchoredNodes(node.id, reactFlowInstance).map<any>((n: Node) => {
    return { nodeId: n.id };
  });
  if (anchoredNodes) {
    anchoredNodes.forEach((anchoredProperties: AnchorProperties) => {
      const anchorDestination = node;
      const anchoredNode = reactFlowInstance.getNode(anchoredProperties.nodeId);
      if (anchoredNode && anchoredNode.id !== node.id && anchorDestination.data.nodeProperties.lastPosition) {
        anchoredNode.position.x += anchorDestination.position.x - anchorDestination.data.nodeProperties.lastPosition.x;
        anchoredNode.position.y += anchorDestination.position.y - anchorDestination.data.nodeProperties.lastPosition.y;

        if (!anchoredNode.data.nodeProperties.lastPosition) {
          anchoredNode.data.nodeProperties.lastPosition = { x: 0, y: 0 };
        }
        anchoredNode.data.nodeProperties.lastPosition.x = anchoredNode.position.x;
        anchoredNode.data.nodeProperties.lastPosition.y = anchoredNode.position.y;
      }
    });
  }

  if (!node.data.nodeProperties.lastPosition) {
    node.data.nodeProperties.lastPosition = { x: 0, y: 0 };
  }
  node.data.nodeProperties.lastPosition.x = node.position.x;
  node.data.nodeProperties.lastPosition.y = node.position.y;
};

export const getAllAnchoredNodes = (nodeId: string, reactFlowInstance: ReactFlowInstance) => {
  let parentNode: Node | undefined = undefined;
  let checkingParentNode: Node | undefined = reactFlowInstance.getNode(nodeId);
  while (checkingParentNode) {
    parentNode = checkingParentNode;
    if (checkingParentNode.data.nodeProperties.anchoredToNodeId) {
      checkingParentNode = reactFlowInstance.getNode(checkingParentNode.data.nodeProperties.anchoredToNodeId);
    } else {
      checkingParentNode = undefined;
    }
  }

  return getChildAnchorsRecurse(parentNode!, reactFlowInstance).concat(parentNode!);
};

export const getAnchorDepth = (nodeId: string, reactFlowInstance: ReactFlowInstance) => {
  let depth = -1;
  let checkingParentNode: Node | undefined = reactFlowInstance.getNode(nodeId);

  while (checkingParentNode) {
    if (isChainHead(checkingParentNode.id, reactFlowInstance)) {
      depth++;
    }

    if (checkingParentNode.data.nodeProperties.anchoredToNodeId) {
      checkingParentNode = reactFlowInstance.getNode(checkingParentNode.data.nodeProperties.anchoredToNodeId);
    } else {
      checkingParentNode = undefined;
    }
  }

  return depth;
};

const getChildAnchorsRecurse = (parent: Node, reactFlowInstance: ReactFlowInstance): Node[] => {
  let children: Node[] = [];
  parent.data.nodeProperties.anchoredNodes?.forEach((anchorProperties: AnchorProperties) => {
    const child = reactFlowInstance.getNode(anchorProperties.nodeId)!;
    children.push(child);
    children = children.concat(getChildAnchorsRecurse(child, reactFlowInstance));
  });

  return children;
};

export const onAnchorDropped = (
  nodeId: string,
  anchoredNodeId: string,
  screenPositionX: number,
  screenPositionY: number,
  reactFlowInstance: ReactFlowInstance,
  updateNodeInternals: UpdateNodeInternals,
  viewController: any
) => {
  const node = reactFlowInstance.getNode(nodeId)!;
  const anchoredNode = reactFlowInstance.getNode(anchoredNodeId);

  const flowXOffset = viewController.nodeSelectionListCollapse.nodeSelectionListCollapseState ? 0 : 375;
  const flowYOffset = 70;
  const dropPosition = reactFlowInstance.project({
    x: screenPositionX - flowXOffset,
    y: screenPositionY - flowYOffset
  });
  const intersectingNodes = reactFlowInstance.getIntersectingNodes({
    x: dropPosition.x,
    y: dropPosition.y,
    width: 1,
    height: 1
  });

  if (anchoredNode && intersectingNodes.length === 0) {
    const anchorProperties = node.data.nodeProperties.anchoredNodes.filter(
      (anchorProperties: AnchorProperties) => anchorProperties.nodeId === anchoredNodeId
    )[0];
    node!.data.nodeProperties.anchoredNodes = node?.data.nodeProperties.anchoredNodes.filter(
      (anchoredNode: AnchorProperties) => anchoredNode.nodeId !== anchoredNodeId
    );
    delete anchoredNode.data.nodeProperties.anchoredToNodeId;
    anchoredNode.zIndex = undefined;

    anchoredNode.position = dropPosition;
    anchoredNode.position.x -= 16;
    anchoredNode.position.y -= 16;
    anchoredNode.data.nodeProperties.lastPosition.x = anchoredNode.position.x;
    anchoredNode.data.nodeProperties.lastPosition.y = anchoredNode.position.y;

    const targetNodeId = anchorProperties.area === AnchorArea.Output ? node.id : anchoredNode.id;
    const sourceNodeId = anchorProperties.area === AnchorArea.Input ? node.id : anchoredNode.id;
    const anchoredEdge = reactFlowInstance
      .getEdges()
      .filter((e) => e.target === targetNodeId && e.source === sourceNodeId)[0];
    if (anchoredEdge) {
      anchoredEdge.className = anchoredEdge.className?.replaceAll(" hidden-anchored-edge", "");
      reactFlowInstance.setEdges((edges) => edges.filter((e) => e.id !== anchoredEdge.id).concat(anchoredEdge));
    }

    reactFlowInstance.setNodes((nodes: Node<any>[]) =>
      nodes.filter((n) => n.id !== anchoredNodeId).concat(anchoredNode)
    );
    forceNodeUpdate(node!, updateNodeInternals, reactFlowInstance);
    forceNodeUpdate(anchoredNode!, updateNodeInternals, reactFlowInstance);
  }
};

export const getChain = (nodeId: string, reactFlowInstance: ReactFlowInstance) => {
  const node = reactFlowInstance.getNode(nodeId)!;
  let chain: Node[] = [node];
  let endOfChainReached = false;

  while (!endOfChainReached) {
    const chainNodeParentProperties = chain[chain.length - 1]!.data.nodeProperties;
    endOfChainReached = true;

    if (chainNodeParentProperties.anchoredNodes?.length > 0) {
      const firstChainedProperties = chain[chain.length - 1]!.data.nodeProperties.anchoredNodes.filter(
        (anchorProperties: AnchorProperties) =>
          (anchorProperties.area === AnchorArea.Input
            ? Object.keys(chain[chain.length - 1]!.data.nodeProperties.inputs)
            : Object.keys(chain[chain.length - 1]!.data.nodeProperties.outputs)
          ).findIndex((property) => property === anchorProperties.propertyName) === 0
      )[0];
      let chainedNode: Node | undefined = undefined;
      if (firstChainedProperties) {
        chainedNode = reactFlowInstance.getNode(firstChainedProperties.nodeId)!;
        if (isChainedToParent(node, chainedNode)) {
          chain.push(chainedNode);
          endOfChainReached = false;
        }
      }
    }
  }

  return chain;
};

export const isChainHead = (nodeId: string, reactFlowInstance: ReactFlowInstance) => {
  const node = reactFlowInstance.getNode(nodeId)!;

  if (node.data.nodeProperties.anchoredToNodeId) {
    const nodeParent = reactFlowInstance.getNode(node.data.nodeProperties.anchoredToNodeId)!;
    const anchorProperties = nodeParent.data.nodeProperties.anchoredNodes.filter(
      (n: AnchorProperties) => n.nodeId === node.id
    )[0];
    if (
      nodeParent.data.nodeProperties.anchoredToNodeId &&
      Object.keys(
        anchorProperties.area === AnchorArea.Input
          ? nodeParent.data.nodeProperties.inputs
          : nodeParent.data.nodeProperties.outputs
      ).findIndex((property) => property === anchorProperties.propertyName) === 0 &&
      isChainedToParent(nodeParent, node)
    ) {
      return false;
    }
  }

  return true;
};

const isChainedToParent = (parentNode: Node<any>, anchoredNode: Node<any>) =>
  Object.keys(parentNode.data.nodeProperties.outputs).length === 1 &&
  Object.keys(anchoredNode.data.nodeProperties.inputs).length > 0;
