import React, { FunctionComponent } from "react";
import { Edge, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { Checkbox, Form, RequiredState } from "@plex/react-components";
import { camelCaseToSpacesRegEx } from "./FormConstants";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { IDataParam } from "../NodeTypes/Base";
import "./NodePropertiesForm.scss";
import { forceNodeUpdate } from "../Util/NodeUtil";

export interface IInputsFormSectionProps extends INodeConfigPropertiesProps {
  onEdgeDelete: (edge: Edge) => void;
}

export const EnableInputsFormSection: FunctionComponent<IInputsFormSectionProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  let [, setState] = React.useState({ value: props.node?.data.nodeProperties || {} });

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Form.Section title={props.sectionName} key={props.sectionName}>
          <div className="input-select-section flowinput-scroll-container enable-io-formsection">
            {Object.keys(props.node?.data.nodeProperties.inputs).map((inputName: string) => {
              const input: IDataParam = props.node?.data.nodeProperties.inputs[inputName];
              let readableInputName = inputName;
              readableInputName = readableInputName.charAt(0).toUpperCase() + readableInputName.slice(1);
              readableInputName = readableInputName.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5");
              let readableType = input.type.replace(camelCaseToSpacesRegEx, "$1$4 $2$3$5").toLowerCase();

              readableInputName = readableInputName.replaceAll("_", " ");

              return (
                <Form.FieldPair
                  labelText={readableInputName}
                  key={inputName}
                  required={input.required ? RequiredState.required : undefined}
                >
                  <div style={{ display: "flex", marginTop: "8px" }}>
                    <Checkbox
                      checked={props.node?.data.nodeProperties.inputs[inputName].enabled === true}
                      disabled={input.required}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.node!.data.nodeProperties.inputs[inputName].enabled = e.currentTarget.checked;
                        if (e.currentTarget.checked === false) {
                          let deletedEdge = reactFlowInstance
                            .getEdges()
                            .filter((e: Edge<any>) => e.sourceHandle === inputName + "-" + input.type + "-input")[0]!;
                          reactFlowInstance.setEdges(
                            reactFlowInstance
                              .getEdges()
                              .filter((e: Edge<any>) => e.sourceHandle !== inputName + "-" + input.type + "-input")
                          );
                          if (deletedEdge) {
                            props.onEdgeDelete(deletedEdge);
                            forceNodeUpdate(
                              reactFlowInstance.getNode(deletedEdge.target)!,
                              updateNodeInternals,
                              reactFlowInstance
                            );
                          }
                        }
                        setState({ value: props.node!.data.nodeProperties.inputs[inputName].enabled });
                        props.forceNodeUpdate();
                      }}
                    />
                    <div className="enable-io-formsection-readabletype">{readableType}</div>
                  </div>
                </Form.FieldPair>
              );
            })}
          </div>
        </Form.Section>
      </div>
    </div>
  );
};
