import React, { FunctionComponent } from "react";
import { Edge, useReactFlow, useUpdateNodeInternals } from "reactflow";
import { Dropdown } from "@plex/react-components";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import {
  DataType,
  IDataType,
  convertToList,
  shouldConvertToList,
  isObjectLike,
  isListType
} from "../NodeTypes/TypeDefinitions";
import {
  IEdgeDeletionMode,
  canDeleteEdge,
  deleteEdgesFromNodes,
  getDeletingEdgesFromNodes,
  updateLinkedSchemas
} from "../Util/EdgeUtil";
import { IDataPropertyCountOptions, INodeProperty } from "../NodeTypes/Base";
import { addDataPropertiesFromCount } from "../NodePropertiesForm/DataPropertyCountFormField";
import { getSchemaId } from "../NodeTypes/DataSchemas";
import { useViewController } from "../ViewContext";

export interface IDataTypeProps extends INodeConfigPropertiesProps {
  dataTypes: IDataType[];
  properties?: string[];
}

export const DataTypeFormField: FunctionComponent<IDataTypeProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const viewController = useViewController();
  let [, setState] = React.useState({ result: props.node?.data.nodeProperties || {} });
  const selectingFromListTypes = props.dataTypes.some((t: { key: string; value: string }) =>
    isListType(t.key as DataType)
  );

  return (
    <Dropdown
      key={`${props.node.type}  ${props.sectionName} Value Type`}
      items={props.dataTypes}
      keySelector={(item: { [key: string]: string }) => item.key}
      displaySelector={(item: { [key: string]: string }) => item.key}
      selected={[{ key: props.node!.data.nodeProperties[props.name] ?? props.dataTypes[0] }]}
      onSelectionChanged={(values) => {
        let inputChanged = false;
        let outputChanged = false;

        if (props.properties) {
          props.properties.forEach((dataPropertyName: string) => {
            const inputDataProperty = props.node!.data.nodeProperties.inputs[dataPropertyName];
            const outputDataProperty = props.node!.data.nodeProperties.outputs[dataPropertyName];
            if (inputDataProperty) {
              inputChanged = true;
            }
            if (outputDataProperty) {
              outputChanged = true;
            }
          });
        }

        const edgeDeletionMode: IEdgeDeletionMode = {
          inputEdges: inputChanged,
          outputEdges: outputChanged,
          controlEdges: false
        };

        const deletingEdges = getDeletingEdgesFromNodes([props.node!.id], reactFlowInstance, edgeDeletionMode);

        if (!deletingEdges.every((e: Edge) => canDeleteEdge(e, reactFlowInstance, viewController.bannerContext))) {
          return;
        }

        const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(props.node?.type!);
        const hadValue = props.node!.data.nodeProperties[props.name] !== undefined;

        props.node!.data.nodeProperties[props.name] = values[0].key;

        if (props.properties) {
          props.node!.data.nodeProperties.isNowValue = false;
          props.properties.forEach((dataPropertyName: string) => {
            const inputDataProperty = props.node!.data.nodeProperties.inputs[dataPropertyName];
            const outputDataProperty = props.node!.data.nodeProperties.outputs[dataPropertyName];
            if (inputDataProperty) {
              const isObject = isObjectLike(values[0].key);
              let newType = values[0].key;
              if (shouldConvertToList(newType, inputDataProperty, selectingFromListTypes)) {
                newType = convertToList(newType);
              }
              props.node!.data.nodeProperties.inputs[dataPropertyName] = {
                ...inputDataProperty,
                type: newType,
                schemaId: isObject ? "string" : getSchemaId(newType)
              };
            }
            if (outputDataProperty) {
              const isObject = isObjectLike(values[0].key);
              let newType = values[0].key;
              if (shouldConvertToList(newType, outputDataProperty, selectingFromListTypes)) {
                newType = convertToList(newType);
              }
              props.node!.data.nodeProperties.outputs[dataPropertyName] = {
                ...outputDataProperty,
                type: newType,
                schemaId: isObject ? "string" : getSchemaId(newType)
              };
            }

            const propertyCountConfig = nodeDefinition?.nodeConfigProperties.filter(
              (property: INodeProperty) =>
                (property.options as IDataPropertyCountOptions)?.cloneFrom === dataPropertyName
            )[0];
            if (propertyCountConfig) {
              addDataPropertiesFromCount(
                props.node,
                propertyCountConfig.name,
                propertyCountConfig.options as IDataPropertyCountOptions
              );
            }
          });
        }

        if (hadValue) {
          setState(props.node!.data.nodeProperties[props.name]);
          if (props.node!.data.nodeProperties[props.name] === "now") {
            props.node!.data.nodeProperties.isNowValue = true;
          }
          if (inputChanged || outputChanged) {
            const newEdges = deleteEdgesFromNodes(
              [props.node!.id],
              reactFlowInstance,
              updateNodeInternals,
              edgeDeletionMode
            );
            updateLinkedSchemas(reactFlowInstance.getNodes(), newEdges, updateNodeInternals, reactFlowInstance);
          }
        }
        props.forceNodeUpdate();
      }}
    />
  );
};
