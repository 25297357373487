import React, { FunctionComponent } from "react";
import { useReactFlow, useUpdateNodeInternals, Node, Edge } from "reactflow";
import { IDataColumnDefProps, ITextQuery, PickerInput, IBannerContext, BannerStatus } from "@plex/react-components";
import { IDataSourceAPI } from "../NodeTypes/TypeDefinitions";
import {
  getDsApiInputs,
  getDsApiOutputs,
  setResultDataSourceApiSpec
} from "../NodeTypes/DataSourceApi/DataSourceAPiSpecs";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import {
  addObjectListSchemaForNode,
  addObjectSchemaForNode,
  getEmptySchemaId,
  getSchemaId
} from "../NodeTypes/DataSchemas";
import { getApiInputs } from "../NodeTypes/PlexOpenApi/PlexOpenApiSpecs";
import { IDataParam, INodeProperty, INodeTypeDefinition } from "../NodeTypes/Base/NodeTypeDefinitions";
import { usefunctionSubscriber } from "../FunctionSubscriberContext/FunctionSubscriberContext";
import { allEdgesDeletionMode, canDeleteEdge, deleteEdgesFromNodes, updateLinkedSchemas } from "../Util/EdgeUtil";
import "./PickerFormField.scss";
import { useViewController } from "../ViewContext";
import { convertKeysToCamelCaseArray, convertKeysToLowerCaseNested } from "../Util/KeyFormatter";

export const DataSourcePickerFormField: FunctionComponent<INodeConfigPropertiesProps> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  const viewController = useViewController();
  const [{ dsApiPickerSelected, dsApiPickerData, searchText }, setDsApiPicker] = React.useState({
    dsApiPickerSelected: props.node?.data.nodeProperties.dataSourceId
      ? [
          {
            id: props.node?.data.nodeProperties.dataSourceId,
            name: props.node?.data.nodeProperties.dataSourceName
          } as IDataSourceAPI
        ]
      : ([] as IDataSourceAPI[]),
    dsApiPickerData: [] as { id: string; name: string }[],
    searchText: ""
  });
  const [, _setState] = React.useState({ value: props.node?.data.nodeProperties || {} });
  const [recordLimitExceededState, setRecordLimitExceededState] = React.useState(false);
  const [searchErrorState, setSearchErrorState] = React.useState("");
  const { plexFetchDsApi, plexFetchDsDetailApi, plexShowOverlay } = usefunctionSubscriber();

  const getNodeInputs = (nodeType: string, category: string, apiTitle: string, apiId: string) => {
    if (nodeType === "plexOpenApi") {
      return getApiInputs(category, apiTitle, apiId);
    } else if (nodeType === "callDataSource") {
      return getDsApiInputs(category, apiTitle, apiId);
    }

    return [];
  };

  const getNodeOutputs = (node: Node<any>) => {
    if (node!.data.nodeProperties.dataSourceId) {
      return getDsApiOutputs("Commands", node!.data.nodeProperties.dataSourceId.toString());
    }
    return [];
  };

  const handleDsPickerSearch = (query: ITextQuery, cols: Array<IDataColumnDefProps<IDataSourceAPI>>) => {
    let dsApis: { id: string; name: string }[] = [];

    if (plexFetchDsApi) {
      plexFetchDsApi(query?.displayText)
        .then((res: any) => {
          const resultData = convertKeysToCamelCaseArray(res.Data);

          setRecordLimitExceededState(false);
          setSearchErrorState("");

          if (cols.length === 0) {
            dsApis = resultData.filter((x) => (x.name || "").toLowerCase().includes(query.query.toLowerCase()));
          } else {
            dsApis = resultData.filter((row: any) =>
              query.isMatch(cols.map((col) => (col.valueSelector ? String(col.valueSelector(row)) : "")))
            );
          }

          setDsApiPicker((x: any) => ({ ...x, dsApiPickerData: dsApis }));
        })
        .catch((e) => {
          setSearchErrorState("An error occurred when searching.");
          setRecordLimitExceededState(false);
          console.error(e);
        });
    }
  };

  const handleDsPickerSearchTextChanged = (value: string) => {
    setDsApiPicker((x: any) => ({ ...x, searchText: value }));
  };

  const loadNodeDetail = (values: any, props: INodeConfigPropertiesProps, reactFlowInstance) => {
    const nodeDefinition = globalThis.nodeTypeDefinitions.getDefinition(props.node.type!)!;

    if (values.length > 0) {
      props.node!.data.nodeProperties.dataSourceId = parseInt(values[0].id);
      props.node!.data.nodeProperties.dataSourceName = values[0].name;
    } else {
      props.node!.data.nodeProperties.dataSourceId = null;
      props.node!.data.nodeProperties.dataSourceName = null;
    }
    props.node!.data.nodeProperties.inputs = {};
    props.node!.data.nodeProperties.outputs = {};
    nodeDefinition.dataOutputs.forEach(
      (dataOutput: IDataParam) => (props.node!.data.nodeProperties.outputs[dataOutput.name] = { ...dataOutput })
    );
    deleteEdgesFromNodes([props.node!.id], reactFlowInstance, updateNodeInternals, {
      ...allEdgesDeletionMode,
      controlEdges: false,
      outputEdges: false
    });

    if (props.node.data.nodeProperties.dataSourceId) {
      const nodeDefinition: INodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(props.node.type!)!;
      const dataSourceProperty: INodeProperty = nodeDefinition.nodeConfigProperties.filter(
        (p: INodeProperty) => p.name === "dataSourceId"
      )[0]!;
      const schemaSourceSystem: string | undefined = dataSourceProperty.schemaSourceSystemType;
      const schemaSourceId: string = props.node.data.nodeProperties.dataSourceId.toString();
      addObjectListSchemaForNode(props.node!, ["rows"], schemaSourceSystem!, schemaSourceId);
      addObjectSchemaForNode(props.node!, ["outputs"], schemaSourceSystem!, schemaSourceId);
    }

    if (values.length === 0) {
      props.forceNodeUpdate();
      return;
    }

    getNodeInputs(
      props.node?.data.nodeProperties.type,
      "Commands",
      "",
      props.node.data.nodeProperties.dataSourceId.toString()
    ).forEach((input: any) => {
      if (!props.node!.data.nodeProperties.inputs[input.name]) {
        props.node!.data.nodeProperties.inputs[input.name] = {
          schemaId: getSchemaId(input.type),
          required: input.required === true,
          type: input.type,
          enabled: input.required === true,
          wireUp: ""
        };
      }
    });

    getNodeOutputs(props.node!).forEach((output: any) => {
      const schemaId: string = getSchemaId(output.type, props.node, [output.name]);
      props.node!.data.nodeProperties.outputs[output.name] = {
        schemaId: schemaId ?? getEmptySchemaId(output.type),
        enabled: true,
        channel: output.channel,
        type: output.type,
        isArray: output.isArray,
        isErrorCode: output.isErrorCode
      };
    });

    props.forceNodeUpdate();
  };

  const loadDsApiDetail = (
    values: any,
    props: INodeConfigPropertiesProps,
    reactFlowInstance,
    bannerContext?: IBannerContext,
    callback?: () => void
  ) => {
    let result: any;

    if (values.length === 0) {
      loadNodeDetail(values, props, reactFlowInstance);
      if (callback) {
        callback();
      }
      return;
    }

    const id = values[0].id;
    if (plexFetchDsDetailApi) {
      plexFetchDsDetailApi(id)
        .then((res) => {
          const response = JSON.parse(res.Data.DataSource);
          result = convertKeysToLowerCaseNested(response);

          setResultDataSourceApiSpec(result);

          result.id = id;
          result.inputs = result.inputs.filter((i: any) => i.name !== "Plexus_Customer_No" && i.name !== "PCN");

          sessionStorage.setItem("dsApi_" + id, JSON.stringify(result));

          loadNodeDetail(values, props, reactFlowInstance);

          plexShowOverlay && plexShowOverlay(false);

          if (callback) {
            callback();
          }
        })
        .catch((e) => {
          const sessionStorageDsApi = sessionStorage.getItem("dsApi_" + id);
          if (sessionStorageDsApi) {
            result = JSON.parse(sessionStorageDsApi);
          } else {
            bannerContext?.addMessage("An error occurred retrieving data source metadata.", BannerStatus.error);
            console.log(e);
          }
          plexShowOverlay && plexShowOverlay(false);
        });
    }
  };

  return (
    <PickerInput<IDataSourceAPI>
      key="Data Source Picker"
      dialogTitle="Data Source"
      initialWidth={900}
      disabled={false}
      selected={dsApiPickerSelected}
      onSelectionChanged={(values: any) => {
        const outputEdges = reactFlowInstance.getEdges().filter((e: Edge) => e.target === props.node.id);
        if (!outputEdges.every((e: Edge) => canDeleteEdge(e, reactFlowInstance, viewController.bannerContext))) {
          return;
        }
        if (values.length === 0) {
          setDsApiPicker((x) => ({ ...x, dsApiPickerSelected: [], searchText: "" }));
          loadDsApiDetail(values, props, reactFlowInstance, viewController.bannerContext, () => {
            updateLinkedSchemas(
              reactFlowInstance.getNodes(),
              reactFlowInstance.getEdges(),
              updateNodeInternals,
              reactFlowInstance
            );
          });
          return;
        }

        if (props.node!.data.nodeProperties.dataSourceId === values[0]?.id) {
          return;
        }
        setDsApiPicker((x) => ({ ...x, dsApiPickerSelected: values.length > 0 ? [values[0]] : [], searchText: "" }));

        if (values.length > 0) {
          plexShowOverlay && plexShowOverlay(true);
          // load
          loadDsApiDetail(values, props, reactFlowInstance, undefined, () => {
            updateLinkedSchemas(
              reactFlowInstance.getNodes(),
              reactFlowInstance.getEdges(),
              updateNodeInternals,
              reactFlowInstance
            );
            setTimeout(() => props.forceNodeUpdate(), 80);
          });
        }
      }}
      keySelector={(row: IDataSourceAPI) => row?.id}
      displaySelector={(row: IDataSourceAPI) => row?.name}
      data={dsApiPickerData}
      onSearch={handleDsPickerSearch}
      searchDisabled={false}
      isOpen={false}
      multiSelect={false}
      maxDisplay={500}
      recordLimitExceeded={recordLimitExceededState}
      errorMessage={searchErrorState ? searchErrorState : undefined}
      searchText={searchText}
      onSearchTextChange={handleDsPickerSearchTextChanged}
    >
      <PickerInput.Column<IDataSourceAPI>
        key="1"
        id="DataSourceDatabaseName"
        title="Database"
        valueSelector={(row: IDataSourceAPI) => row.database}
      >
        {(row: IDataSourceAPI) => row.database}
      </PickerInput.Column>

      <PickerInput.Column<IDataSourceAPI>
        key="2"
        id="DataSourceName"
        title="Name"
        valueSelector={(row: IDataSourceAPI) => row.storedProcedure}
        searchable
      >
        {(row: IDataSourceAPI) => row.storedProcedure}
      </PickerInput.Column>

      <PickerInput.Column<IDataSourceAPI>
        key="3"
        id="DataSourceInputCount"
        title="Inputs"
        valueSelector={(row: IDataSourceAPI) => row.inputCount}
      >
        {(row: IDataSourceAPI) => row.inputCount}
      </PickerInput.Column>

      <PickerInput.Column<IDataSourceAPI>
        key="4"
        id="DataSourceOutputCount"
        title="Outputs"
        valueSelector={(row: IDataSourceAPI) => row.outputCount}
      >
        {(row: IDataSourceAPI) => row.outputCount}
      </PickerInput.Column>
    </PickerInput>
  );
};
