import React, { FunctionComponent, useState, useEffect, PropsWithChildren, useRef } from "react";
import { SidePanel, MountSide, SideTab, Tabs } from "@plex/react-components";
import { NodeSelectionList } from "../NodeSelectionList/NodeSelectionList";
import { INodePropertiesFormData } from "../NodePropertiesForm/NodePropertiesForm";
import { SwitchInputOutputCondition } from "../NodePropertiesForm/SwitchInputOutputCondition";
import { MathExpressionCondition } from "../NodePropertiesForm/MathExpressionCondition";
import { useReactFlow, Node, Position, useStore, useUpdateNodeInternals } from "reactflow";
import { BaseInputOutput, Condition, MathExpression, getTypeName, isObjectLike } from "../NodeTypes/TypeDefinitions";

import { addToGroup, getIntersectingGroups, getSavedGroup, importGroup } from "../NodeTypes/GroupNode/groupUtil";
import { DocumentPropertiesForm, IDocumentPropertiesForm } from "../FlowDocument/DocumentPropertiesForm";
import { onAnchorDropped } from "../Util/AnchorUtil";
import { AddMenuNodePopulater } from "../AddNodeMenu/AddMenuNodePopulation";
import { useViewController } from "../ViewContext";
import { ITestPropertiesForm, TestPropertiesForm } from "../FlowDocument/TestPropertiesForm";
import "./DesignerContainer.scss";
import { camelToUpperSnakeCase } from "../FlowDocument/DocumentProcessor";
import { canAddNode } from "../Util/NodeUtil";
import { getSchemaId } from "../NodeTypes/DataSchemas";
import { INodeProperty } from "../NodeTypes/Base";
import { NodeConfigPropertyType } from "../FlowDocument/PropertyTypeDefinitions";

export interface IDesignerContainerProps extends PropsWithChildren {
  documentProperties: IDocumentPropertiesForm;
  testProperties: ITestPropertiesForm;
  propertiesTabIndexState: number;
  setPropertiesTabIndexState: React.Dispatch<React.SetStateAction<number>>;
  triggerRender: () => void;
  readOnlyFlow?: boolean;
}

const getIdNumber = (nodeId: string) => {
  const nodeIdParts: string[] = nodeId.split("_");
  return parseInt(nodeIdParts[nodeIdParts.length - 1] ?? "0");
};

export const getNewId = (nodeType: string, reactFlowInstance: any) => {
  const nodes: Node<any>[] = reactFlowInstance.getNodes();

  const filteredNodes = nodes.filter((n: Node<any>) => n.type === nodeType);

  const sortedNodes = filteredNodes.sort((a: Node<any>, b: Node<any>) => getIdNumber(b.id) - getIdNumber(a.id));

  let newIdNumber = 1;

  if (sortedNodes.length > 0) {
    const highestIdNode = sortedNodes[0]!;
    const highestIdNumber = getIdNumber(highestIdNode.id);

    newIdNumber = highestIdNumber + 1;
  }

  return `${camelToUpperSnakeCase(nodeType)}_${newIdNumber}`;
};

// create node logic that could return a node object that need to be pushed inside flow, exposed it to module level so it could be used else place
export const createNewNodeMeta = (
  nodeType: string,
  category: string | null,
  api: string | null,
  apiAction: string | null,
  apiFamily: string | null,
  savedGroupName: string | null,
  x: number,
  y: number,
  useExistingId: string = "",
  properties = {}
): any => {
  const reactFlowInstance = AddMenuNodePopulater._reactFlowInstance;
  const updateNodeInternals = AddMenuNodePopulater._reactFlowUpdateNodeInternals;
  const id = useExistingId === "" ? getNewId(nodeType, reactFlowInstance) : useExistingId;
  const flowXOffset = 375;
  const flowYOffset = 70;
  const halfNodeWidth = 100;
  const halfNodeHeight = 22;
  let position = reactFlowInstance.project({ x: x - flowXOffset, y: y - flowYOffset });
  position.x -= halfNodeWidth;
  position.y -= halfNodeHeight;

  if (useExistingId !== "") {
    (position.x = x), (position.y = y);
  }

  let newNode: Node = {
    id,
    position: { x: position.x, y: position.y },
    width: 180,
    height: 30,
    data: {
      id: id,
      label: nodeType,
      nodeProperties: { ...properties, type: nodeType, description: "", anchoredNodes: [] } as INodePropertiesFormData
    },
    sourcePosition: Position.Left,
    targetPosition: Position.Right
  };

  if (!useExistingId && nodeType === "constant") {
    newNode.data.nodeProperties.outputs = {
      result: {
        enabled: true,
        type: "string",
        schemaId: "string",
        name: "result",
        hideLabel: true
      }
    };
  }

  if (!useExistingId && nodeType === "constantBoolean") {
    newNode.data.nodeProperties.literal = false;
  }

  if (!useExistingId && nodeType === "listItem") {
    newNode.data.nodeProperties.itemPosition = "first";
  }

  if (nodeType.toLowerCase() === "Coalesce".toLowerCase()) {
    newNode.type = "switch";
    newNode.data.nodeProperties.switchType = "Coalesce";
  }

  if (nodeType.toLowerCase() === "Branch".toLowerCase()) {
    newNode.type = "switch";
    newNode.data.nodeProperties.switchType = "Branch";
  }

  if (nodeType.toLowerCase() === "Comment".toLowerCase()) {
    newNode.type = "comment";
    newNode.data.nodeProperties = { ...newNode.data.nodeProperties, comment: "" };
  }

  if (nodeType.toLowerCase() === "Group".toLowerCase()) {
    if (savedGroupName) {
      return importGroup(getSavedGroup(savedGroupName), reactFlowInstance, position, updateNodeInternals);
    } else {
      newNode.type = "flowGroup";
      newNode.zIndex = -1001;
      newNode.data.nodeProperties = {
        ...newNode.data.nodeProperties,
        type: nodeType,
        name: "",
        groupInput: [],
        groupOutput: []
      };
    }
  }

  if (nodeType.toLowerCase() === "Math".toLowerCase()) {
    newNode.type = "math";
    newNode.data.nodeProperties = {
      ...newNode.data.nodeProperties,
      type: nodeType,
      mathType: "decimal"
    };
  }

  if (nodeType.toLowerCase() === "listItem".toLowerCase()) {
    newNode.type = "listItem";
    newNode.data.nodeProperties = {
      ...newNode.data.nodeProperties,
      type: nodeType,
      itemType: [{ key: "object", value: "object" }],
      index: 0,
      description: ""
    };
  }

  if (nodeType.toLowerCase() === "forEach".toLowerCase()) {
    newNode.data.nodeProperties = {
      ...newNode.data.nodeProperties,
      itemType: [{ key: "object", value: "object" }]
    };
  }

  if (nodeType === "listSort") {
    newNode.data.nodeProperties = {
      ...newNode.data.nodeProperties,
      label: "Sort Ascending"
    };
  }

  let nodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(nodeType);
  if (nodeTypeDefinition) {
    newNode.type = nodeTypeDefinition.id;
    if (!newNode.data.nodeProperties) {
      newNode.data.nodeProperties = {};
    }

    if (!newNode.data.nodeProperties.type) {
      newNode.data.nodeProperties.type = nodeTypeDefinition.id;
    }

    if (!newNode.data.nodeProperties.label) {
      newNode.data.nodeProperties.label = nodeTypeDefinition.label;
    }
    newNode.data.nodeProperties.description = "";
    newNode.data.nodeProperties.controlOutputs = nodeTypeDefinition.controlOutputs;

    switch (nodeType) {
      case "flowGroup":
        newNode.data.nodeProperties.groupInput = [];
        newNode.data.nodeProperties.groupOutput = [];
        break;
    }

    if (!newNode.data.nodeProperties.inputs) {
      newNode.data.nodeProperties.inputs = {};
      nodeTypeDefinition.dataInputs.forEach(
        (i) =>
          (newNode.data.nodeProperties.inputs[i.name] = {
            required: i.required,
            enabled: i.enabled,
            type: getTypeName(i.type),
            schemaId: i.schemaId ?? (!isObjectLike(i.type) ? getSchemaId(i.type) : undefined),
            label: i.label,
            hideLabel: i.hideLabel
          })
      );
    }

    if (!newNode.data.nodeProperties.outputs) {
      newNode.data.nodeProperties.outputs = {};
      nodeTypeDefinition.dataOutputs.forEach(
        (o) =>
          (newNode.data.nodeProperties.outputs[o.name] = {
            required: o.required,
            enabled: o.enabled,
            type: getTypeName(o.type),
            schemaId: o.schemaId ?? (!isObjectLike(o.type) ? getSchemaId(o.type) : undefined),
            label: o.label,
            hideLabel: o.hideLabel
          })
      );
    }
  }

  return newNode;
};

export const DesignerContainer: FunctionComponent<IDesignerContainerProps> = ({
  children,
  documentProperties,
  testProperties,
  propertiesTabIndexState,
  setPropertiesTabIndexState,
  triggerRender,
  readOnlyFlow
}) => {
  const reactFlowInstance = useReactFlow();
  const viewController = useViewController();
  const [showSecondary, setShowSecondary] = useState({
    show: false,
    expanded: false,
    propertyType: "NodeProperty",
    panelData: {}
  });

  const [switchProperty, _setSwitchProperty] = useState<BaseInputOutput>({
    name: "",
    type: "",
    schemaId: "",
    isArray: false,
    uniqueId: "",
    required: false,
    enabled: false,
    nullable: false,
    conditions: [],
    expressions: []
  });
  const [switchConditions, _setSwitchConditions] = useState<Condition[]>([]);
  const [mathExpressions, _setMathExpressions] = useState<MathExpression[]>([]);
  const [secondaryNode, _setSecondaryNode] = useState<any>();
  let updateNodeInternals = useUpdateNodeInternals();
  const addSelectedNodes = useStore((actions) => actions.addSelectedNodes);
  const [traceOpenFiredState, setTraceOpenFiredState] = useState<boolean>(false);
  const rightSidePropertiesPanelIsOpen = useRef<boolean>(false);

  const displaySecondary = (showPanel: boolean) => {
    setShowSecondary({
      show: showPanel,
      expanded: false,
      propertyType: showSecondary.propertyType,
      panelData: showSecondary.panelData
    });
  };

  const selectNode = (nodeId: string) => {
    displaySecondary(false);
    setTimeout(() => {
      addSelectedNodes([nodeId]);
      setShowSecondary({ show: false, expanded: false, propertyType: "unknown", panelData: { data: {} } });
    }, 0);
  };

  const updateContainerWidth = () => {
    document.getElementById("flowContainer")?.classList.remove("flow-one-panel-collapse-width");
    document.getElementById("flowContainer")?.classList.remove("flow-two-panel-collapse-width");

    let leftPanelHidden = document
      .getElementById("spNodeContainer")
      ?.classList?.contains("plex-fd-side-panel-hidden-left");
    let rightPanelHidden = document
      .getElementById("spPropertiesContainer")
      ?.classList?.contains("plex-fd-side-panel-hidden-right");

    if (leftPanelHidden || rightPanelHidden) {
      if (leftPanelHidden && rightPanelHidden) {
        document.getElementById("flowContainer")?.classList.add("flow-two-panel-collapse-width");
      } else {
        document.getElementById("flowContainer")?.classList.add("flow-one-panel-collapse-width");
      }
    }
  };

  const onNodesCollapseClick = (e: React.MouseEvent) => {
    document.getElementById("spNodeContainer")?.classList.toggle("plex-fd-side-panel-hidden-left");
    document.getElementById("stNodeContainer")?.classList.toggle("plex-fd-side-tab-hidden-left");
    updateContainerWidth();
    viewController.nodeSelectionListCollapse.setNodeSelectionListCollapseState(true);
  };

  const onNodesExpandClick = (e: React.MouseEvent) => {
    document.getElementById("spNodeContainer")?.classList.toggle("plex-fd-side-panel-hidden-left");
    document.getElementById("stNodeContainer")?.classList.toggle("plex-fd-side-tab-hidden-left");
    updateContainerWidth();
    viewController.nodeSelectionListCollapse.setNodeSelectionListCollapseState(false);
  };

  const onPropertiesCollapseClick = (e: React.MouseEvent) => {
    document.getElementById("spPropertiesContainer")?.classList.toggle("plex-fd-side-panel-hidden-right");
    document.getElementById("stPropertiesContainer")?.classList.toggle("plex-fd-side-tab-hidden-right");
    displaySecondary(false);
    updateContainerWidth();

    triggerRender();

    if (testProperties.testSettings.isValid) {
      setTraceOpenFiredState(false);
    }
  };

  const onPropertiesExpandClick = (e?: React.MouseEvent) => {
    document.getElementById("spPropertiesContainer")?.classList.toggle("plex-fd-side-panel-hidden-right");
    document.getElementById("stPropertiesContainer")?.classList.toggle("plex-fd-side-tab-hidden-right");
    updateContainerWidth();
  };

  const onProperties2CollapseClick = (e?: React.MouseEvent) => {
    document.getElementById("spProperties2Container")?.classList.toggle("plex-fd-side-panel-hidden-right");
    document.getElementById("stProperties2Container")?.classList.toggle("plex-fd-side-tab-hidden-right");
    updateContainerWidth();
  };

  useEffect(() => {
    var propContainerClasses: DOMTokenList | undefined = document.getElementById("spProperties2Container")?.classList;
    var propTabClasses: DOMTokenList | undefined = document.getElementById("stProperties2Container")?.classList;
    if (showSecondary.expanded) {
      if (propContainerClasses?.contains("plex-fd-side-panel-hidden-right")) {
        propContainerClasses?.toggle("plex-fd-side-panel-hidden-right");
      }
    } else {
      if (!propContainerClasses?.contains("plex-fd-side-panel-hidden-right")) {
        propContainerClasses?.toggle("plex-fd-side-panel-hidden-right");
      }
    }
    if (showSecondary.show) {
      if (!propTabClasses?.contains("plex-fd-side-tab-hidden-right")) {
        propTabClasses?.toggle("plex-fd-side-tab-hidden-right");
      }
    } else {
      if (propTabClasses?.contains("plex-fd-side-tab-hidden-right")) {
        propTabClasses?.toggle("plex-fd-side-tab-hidden-right");
      }
    }
  }, [showSecondary]);

  const toggleSecondaryProperty = () => {
    //delay slightly
    document.getElementById("spProperties2Container")?.classList?.toggle("plex-fd-side-panel-hidden-right");
    document.getElementById("stProperties2Container")?.classList?.toggle("plex-fd-side-tab-hidden-right");
    updateContainerWidth();
  };

  const onProperties2ExpandClick = (e: React.MouseEvent) => {
    toggleSecondaryProperty();
  };

  const sideTabNodesTitle = () => {
    return (
      <div className="plex-fd-left-tab-header-title">
        <div className="plex-fd-side-tab-title-text">Nodes</div>
      </div>
    );
  };

  const sideTabPropertiesTitle = () => {
    return (
      <div className="plex-fd-right-tab-header-title">
        <div className="plex-fd-side-tab-title-text">Properties</div>
      </div>
    );
  };

  const sideTabProperties2Title = () => {
    return (
      <div className="plex-fd-right-tab-header-title">
        <div className="plex-fd-side-tab-title-text">Secondary</div>
      </div>
    );
  };

  const createNode = (
    nodeType: string,
    category: string | null,
    api: string | null,
    apiAction: string | null,
    apiFamily: string | null,
    savedGroupName: string | null,
    x: number,
    y: number,
    useExistingId: string = "",
    properties: any = {}
  ): any => {
    if (!nodeType) {
      return;
    }

    if (!canAddNode(nodeType, reactFlowInstance)) {
      return;
    }

    const newNode: any = createNewNodeMeta(
      nodeType,
      category,
      api,
      apiAction,
      apiFamily,
      savedGroupName,
      x,
      y,
      useExistingId,
      properties
    );

    let groups = getIntersectingGroups(newNode, reactFlowInstance);
    if (groups.length > 0) {
      addToGroup(newNode, groups[0]!, reactFlowInstance, updateNodeInternals);
    }

    reactFlowInstance.addNodes(newNode);
    selectNode(newNode.id);
    setShowSecondary({ show: false, expanded: false, propertyType: "unknown", panelData: { data: newNode } });

    const hasAutoOpenProperty = (properties: INodeProperty[] | undefined): boolean => {
      return (
        !!properties &&
        properties.some(
          (property) =>
            property.propertyType !== NodeConfigPropertyType.DataInputs &&
            property.propertyType !== NodeConfigPropertyType.DataOutputs &&
            property.propertyType !== NodeConfigPropertyType.DataType &&
            !property.outsideFormEdit
        )
      );
    };

    const selectedNodeConfigProperties = globalThis.nodeTypeDefinitions.getDefinition(newNode.data.nodeProperties.type)
      ?.nodeConfigProperties;

    if (hasAutoOpenProperty(selectedNodeConfigProperties)) {
      setTimeout(() => {
        globalThis.setPropertiesPopupState(true);
      }, 50);
    }

    return newNode;
  };

  // Exposed create node method to be used by Add Node Populator
  AddMenuNodePopulater._createNodeNatural = createNode;

  let traceFormLogValid: HTMLElement | null;
  rightSidePropertiesPanelIsOpen.current =
    document.getElementById("stPropertiesContainer")?.classList[0] === undefined ? false : true;

  useEffect(() => {
    traceFormLogValid = document.getElementById("flows-trace-form-test-log-valid");

    if (
      !testProperties.testSettings.isValid &&
      !traceOpenFiredState &&
      (!traceFormLogValid || traceFormLogValid?.clientHeight === 0)
    ) {
      // React component library does expose open/closed state, so force it to open with events
      if (!rightSidePropertiesPanelIsOpen.current) {
        // somehow firing twice should be fire once
        (document.querySelector("#stPropertiesContainer > div > div") as HTMLElement | null)?.click();
      }

      document.getElementById("react-tabs-2")?.click();
      setTraceOpenFiredState(true);
    }
  });

  useEffect(() => {
    if (
      (testProperties.testSettings.errors.length > 0 || testProperties.testSettings.replay.nodeTraces.length > 0) &&
      !rightSidePropertiesPanelIsOpen.current
    ) {
      (document.querySelector("#stPropertiesContainer > div > div") as HTMLElement | null)?.click();
      document.getElementById("react-tabs-2")?.click();
      setTraceOpenFiredState(true);
    }
  }, [testProperties.testSettings.errors, testProperties.testSettings.replay.nodeTraces]);

  const flowContainerClassName = readOnlyFlow
    ? "Flow flow-one-panel-collapse-width flow-container-readonly"
    : "Flow flow-one-panel-collapse-width";

  return (
    <div className="designer-container">
      {readOnlyFlow ? (
        <></>
      ) : (
        <>
          <div id="spNodeContainer" className="plex-fd-side-panel-to-left">
            <SidePanel
              title={<div className="plex-fd-side-panel-title-text">Nodes</div>}
              onCollapse={onNodesCollapseClick}
              onClose={onNodesCollapseClick}
              mountSide={MountSide.left}
            >
              <NodeSelectionList />
            </SidePanel>
          </div>
          <div id="stNodeContainer" className="plex-fd-side-tab-hidden-left">
            <SideTab
              title={sideTabNodesTitle()}
              onExpand={onNodesExpandClick}
              className="plex-fd-side-tab-on-left"
              mountSide={MountSide.left}
            />
          </div>
        </>
      )}
      <div
        id="flowContainer"
        className={flowContainerClassName}
        data-testid="flowContainer"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          if (e.dataTransfer.getData("isAnchorDrop")) {
            onAnchorDropped(
              e.dataTransfer.getData("nodeId"),
              e.dataTransfer.getData("anchoredNodeId"),
              e.clientX,
              e.clientY,
              reactFlowInstance,
              updateNodeInternals,
              viewController
            );
          } else {
            createNode(
              e.dataTransfer.getData("nodeType"),
              e.dataTransfer.getData("category"),
              e.dataTransfer.getData("type"),
              e.dataTransfer.getData("apiAction"),
              e.dataTransfer.getData("apiFamily"),
              e.dataTransfer.getData("savedGroupName"),
              e.clientX,
              e.clientY
            );
          }
        }}
      >
        {children}
      </div>
      {showSecondary.show && (
        <>
          <div id="spProperties2Container" className="plex-fd-side-panel-to-right plex-fd-side-panel-hidden-right">
            <SidePanel
              title={<div className="plex-fd-side-panel-title-text">Secondary</div>}
              onCollapse={onProperties2CollapseClick}
              onClose={onProperties2CollapseClick}
              mountSide={MountSide.right}
            >
              {showSecondary.propertyType == "switch" && (
                <SwitchInputOutputCondition
                  node={secondaryNode}
                  isArray={false}
                  type=""
                  schemaId=""
                  uniqueId={switchProperty.uniqueId}
                  expressions={null}
                  conditions={switchConditions}
                  required={switchProperty.required}
                  nullable={switchProperty.nullable}
                  name={switchProperty.name}
                  enabled={true}
                ></SwitchInputOutputCondition>
              )}
              {showSecondary.propertyType == "math" && (
                <MathExpressionCondition
                  node={secondaryNode}
                  isArray={false}
                  type=""
                  schemaId=""
                  uniqueId={switchProperty.uniqueId}
                  expressions={mathExpressions}
                  conditions={null}
                  required={switchProperty.required}
                  nullable={switchProperty.nullable}
                  name={switchProperty.name}
                  enabled={true}
                ></MathExpressionCondition>
              )}
            </SidePanel>
          </div>
          <div id="stProperties2Container" className="side-tab-right">
            <SideTab
              title={sideTabProperties2Title()}
              onExpand={onProperties2ExpandClick}
              className="plex-fd-side-tab-on-right"
              mountSide={MountSide.right}
            />
          </div>
        </>
      )}
      <div id="spPropertiesContainer" className="plex-fd-side-panel-to-right plex-fd-side-panel-hidden-right">
        <SidePanel
          title={<div className="plex-fd-side-panel-title-text">Properties</div>}
          onCollapse={onPropertiesCollapseClick}
          onClose={onPropertiesCollapseClick}
          mountSide={MountSide.right}
          containerClassName="plex-fd-properties-panel"
        >
          <Tabs
            selectedIndex={propertiesTabIndexState}
            onSelect={(index: any, _prev: any, _e: any) => {
              setPropertiesTabIndexState(index);
            }}
          >
            <Tabs.TabList>
              <Tabs.Tab>Flow</Tabs.Tab>
              <Tabs.Tab>Replay</Tabs.Tab>
            </Tabs.TabList>
            <Tabs.TabPanel>
              <DocumentPropertiesForm
                document={documentProperties.document}
                setDocumentPropertiesState={documentProperties.setDocumentPropertiesState}
              />
            </Tabs.TabPanel>
            <Tabs.TabPanel>
              <TestPropertiesForm
                testSettings={testProperties.testSettings}
                setTestPropertiesState={testProperties.setTestPropertiesState}
              />
            </Tabs.TabPanel>
          </Tabs>
        </SidePanel>
      </div>
      <div id="stPropertiesContainer">
        <SideTab
          title={sideTabPropertiesTitle()}
          onExpand={onPropertiesExpandClick}
          className="plex-fd-side-tab-on-right"
          mountSide={MountSide.right}
        />
      </div>
    </div>
  );
};
