import React, { FunctionComponent, ReactElement } from "react";
import { useReactFlow, useUpdateNodeInternals } from "reactflow";
import { Form } from "@plex/react-components";
import { useViewController } from "../ViewContext";
import { IDataParam, IDataTypeOptions, INodeProperty } from "../NodeTypes/Base/NodeTypeDefinitions";
import { TextFormField } from "./TextFormField";
import { StopTypeFormField } from "./StopTypeFormField";
import { FlowOutputFormSection } from "./FlowOutputFormSection";
import { ValueFormField } from "./ValueFormField";
import { EnableOutputsFormSection } from "./EnableOutputsFormSection";
import { DataSourcePickerFormField } from "./DataSourcePickerFormField";
import { EnableInputsFormSection } from "./EnableInputsFormSection";
import { FlowInputFormSection } from "./FlowInputFormSection";
import { ItemPositionFormField } from "./ItemPositionFormField";
import { EnumFormField } from "./EnumFormField";
import { DataTypeFormField } from "./DataTypeFormField";
import { forceNodeUpdate } from "../Util/NodeUtil";
import { CheckboxFormField } from "./CheckboxFormField";
import { NumericFormField } from "./NumericFormField";
import { primitiveTypesSelect } from "../NodeTypes/TypeDefinitions";
import { DataInputFormField } from "./DataInputFormField";
import { NodeConfigPropertyType } from "../FlowDocument/PropertyTypeDefinitions";
import { DataPropertyCountFormField } from "../NodePropertiesForm/DataPropertyCountFormField";
import { ViewDataSourceDetailsField } from "./ViewDataSourceDetailsField";
import { StandardObjectPickerFormField } from "./StandardObjectPickerFormField";
import { createIo } from "../NodeTypes/SchemaSystem";
import { usefunctionSubscriber } from "../FunctionSubscriberContext/FunctionSubscriberContext";

export interface IBaseNodeProperties {
  id: string;
}

export const BaseNodePropertiesForm: FunctionComponent<IBaseNodeProperties> = (props) => {
  const reactFlowInstance = useReactFlow();
  const updateNodeInternals = useUpdateNodeInternals();
  let { plexGetCustomFieldsSchema, plexShowOverlay } = usefunctionSubscriber();
  const viewController = useViewController();
  const node = reactFlowInstance.getNode(props.id);

  const defaultSectionName = "Details";

  const updateNode = () => {
    forceNodeUpdate(node, updateNodeInternals, reactFlowInstance);
  };

  const generateFormSections = () => {
    let nodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(node!.type!)!;

    if (!nodeTypeDefinition.nodeConfigProperties) {
      return <div>No Configurable Properties</div>;
    }

    let formSections: JSX.Element[] = [];
    let formRowsForDefaultSection: JSX.Element[] = [];

    formRowsForDefaultSection.push(
      <Form.Row key={`${defaultSectionName} Name`}>
        <Form.FieldPair labelText="Name" key="Name">
          <TextFormField
            name="name"
            sectionName={defaultSectionName}
            label="Name"
            node={node!}
            characterLength={25}
            forceNodeUpdate={updateNode}
          />
        </Form.FieldPair>
      </Form.Row>
    );

    nodeTypeDefinition.nodeConfigProperties.forEach((nodeConfigProperty, nodeConfigPropertyIndex) => {
      if (nodeConfigProperty.sectionName) {
        formSections.push(createNodePropertyFormSection(nodeConfigProperty, nodeConfigPropertyIndex));
      } else {
        formRowsForDefaultSection.push(createNodePropertyFormRow(nodeConfigProperty));
      }
    });

    formSections.unshift(
      <Form.Section title={defaultSectionName} key={defaultSectionName}>
        {formRowsForDefaultSection}
      </Form.Section>
    );

    return formSections;
  };

  const createNodePropertyFormSection = (nodeConfigProperty: INodeProperty, nodeConfigPropertyIndex: number) => {
    let formSection: any = null;
    let formRows: ReactElement[] = [];

    switch (nodeConfigProperty.propertyType) {
      case NodeConfigPropertyType.EnabledInputs:
        formSection = (
          <EnableInputsFormSection
            // Can't have duplicate keys in React. Need this workaround to have multiple
            // EnableInputsFormSections on the same node config dialog.
            key={`EnableInputsFormSection${nodeConfigPropertyIndex}`}
            name={nodeConfigProperty.name}
            sectionName={nodeConfigProperty.sectionName ?? "Input"}
            node={node!}
            forceNodeUpdate={updateNode}
            onEdgeDelete={viewController.validateEdges}
          />
        );
        break;
      case NodeConfigPropertyType.EnabledOutputs:
        formSection = (
          <EnableOutputsFormSection
            key="EnableOutputsFormSection"
            name={nodeConfigProperty.name}
            sectionName={nodeConfigProperty.sectionName ?? "Output"}
            node={node!}
            forceNodeUpdate={updateNode}
            onEdgeDelete={viewController.validateEdges}
          />
        );
        break;
      case NodeConfigPropertyType.DataOutputs:
        formSection = (
          <FlowInputFormSection
            key="FlowInputFormSection"
            name={nodeConfigProperty.name}
            sectionName={nodeConfigProperty.sectionName ?? "Inputs"}
            viewController={viewController}
            node={node!}
            forceNodeUpdate={updateNode}
          />
        );
        break;
      case NodeConfigPropertyType.DataInputs:
        if (node!.type === "flowGroup") {
          if (nodeConfigProperty.name.includes("Input")) {
            formSection = (
              <FlowOutputFormSection
                key="GroupInputFormSection"
                name={nodeConfigProperty.name}
                sectionName={nodeConfigProperty.sectionName ?? "Group Input"}
                sectionType="input"
                node={node!}
                forceNodeUpdate={updateNode}
                onEdgeDelete={viewController.validateEdges}
              />
            );
          } else {
            formSection = (
              <FlowOutputFormSection
                key="GroupOutputFormSection"
                name={nodeConfigProperty.name}
                sectionName={nodeConfigProperty.sectionName ?? "Group Output"}
                node={node!}
                forceNodeUpdate={updateNode}
                onEdgeDelete={viewController.validateEdges}
              />
            );
          }
        } else {
          formSection = (
            <FlowOutputFormSection
              key="FlowOutputFormSection"
              name={nodeConfigProperty.name}
              sectionName={nodeConfigProperty.sectionName ?? "Flow Output"}
              node={node!}
              forceNodeUpdate={updateNode}
              onEdgeDelete={viewController.validateEdges}
            />
          );
        }
        break;
      default:
        formRows.push(createNodePropertyFormRow(nodeConfigProperty));
        break;
    }

    if (!formSection) {
      formSection = (
        <Form.Section
          title={nodeConfigProperty.sectionName ?? defaultSectionName}
          key={nodeConfigProperty.sectionName ?? defaultSectionName}
        >
          {formRows}
        </Form.Section>
      );
    }

    return formSection;
  };

  const createNodePropertyFormRow = (nodeConfigProperty: INodeProperty) => {
    const formFields: ReactElement[] = [];
    const sectionName = nodeConfigProperty.sectionName ?? defaultSectionName;
    const label = nodeConfigProperty.label ?? node!.type!;

    switch (nodeConfigProperty.propertyType) {
      case NodeConfigPropertyType.Text:
        formFields.push(
          <TextFormField
            key={`${sectionName} ${label} Text`}
            name={nodeConfigProperty.name}
            sectionName={sectionName}
            label={label}
            node={node!}
            forceNodeUpdate={updateNode}
          />
        );
        break;
      case NodeConfigPropertyType.Boolean:
        formFields.push(
          <CheckboxFormField
            key={`${sectionName} ${label} Boolean`}
            name={nodeConfigProperty.name}
            sectionName={sectionName}
            label={label}
            node={node!}
            forceNodeUpdate={updateNode}
          />
        );
        break;
      case NodeConfigPropertyType.Integer:
        formFields.push(
          <NumericFormField
            key={`${sectionName} ${label} Number`}
            name={nodeConfigProperty.name}
            sectionName={sectionName}
            label={label}
            node={node!}
            forceNodeUpdate={updateNode}
            numericOptions={{ scale: 0 }}
          />
        );
        break;
      case NodeConfigPropertyType.Decimal:
        formFields.push(
          <NumericFormField
            key={`${sectionName} ${label} Number`}
            name={nodeConfigProperty.name}
            sectionName={sectionName}
            label={label}
            node={node!}
            forceNodeUpdate={updateNode}
          />
        );
        break;
      case NodeConfigPropertyType.DataSource:
        if (node?.type === "callDataSource") {
          formFields.push(
            <DataSourcePickerFormField
              key={`${sectionName} Data Source Picker`}
              name={nodeConfigProperty.name}
              node={node!}
              forceNodeUpdate={updateNode}
            />
          );
        } else if (node?.type === "listSort") {
          formFields.push(
            <DataInputFormField
              key={`${sectionName} Data Input`}
              name={nodeConfigProperty.name}
              sectionName={sectionName}
              label={label}
              dialogTitle={label}
              node={node!}
              forceNodeUpdate={updateNode}
              typesAllowed={["string", "integer", "decimal", "boolean", "date", "dateTime", "time"]}
              searchColumns={[
                { id: "DataSourceName", title: "Name", valueSelector: (row: IDataParam) => row.name },
                { id: "DataSourceType", title: "Type", valueSelector: (row: IDataParam) => row.type }
              ]}
            />
          );
        }
        break;
      case NodeConfigPropertyType.DataPropertyCount:
        formFields.push(
          <DataPropertyCountFormField
            key={`${sectionName} Data Property Count`}
            name={nodeConfigProperty.name}
            node={node!}
            options={nodeConfigProperty.options}
            forceNodeUpdate={updateNode}
          />
        );
        break;
      case NodeConfigPropertyType.DataType:
        if (node?.type === "stop") {
          formFields.push(
            <StopTypeFormField
              key={`${sectionName} Stop Type`}
              name={nodeConfigProperty.name}
              node={node}
              forceNodeUpdate={updateNode}
            />
          );
        } else {
          const options = nodeConfigProperty.options as IDataTypeOptions;
          let dataTypes = primitiveTypesSelect;
          if (options?.dataTypes) {
            dataTypes = options.dataTypes.map((typeName: string) => {
              return { key: typeName, value: typeName };
            });
          }
          formFields.push(
            <DataTypeFormField
              key={`${sectionName} Value Type`}
              name={nodeConfigProperty.name}
              node={node!}
              forceNodeUpdate={updateNode}
              dataTypes={dataTypes}
              properties={options.dataProperties}
            />
          );
        }
        break;
      case NodeConfigPropertyType.DataValue:
        const inputBasisType = "outputType";
        const dataValueFormField: any = ValueFormField({
          name: nodeConfigProperty.name,
          inputBasisType: inputBasisType,
          node: node!,
          forceNodeUpdate: updateNode
        });

        if (dataValueFormField) {
          formFields.push(dataValueFormField!);
        }
        break;
      case NodeConfigPropertyType.Enum:
        const enumFormField: any = EnumFormField({
          name: nodeConfigProperty.name,
          node: node!,
          forceNodeUpdate: updateNode,
          options: nodeConfigProperty.options
        });
        if (enumFormField) {
          formFields.push(enumFormField!);
        }
        break;
      case NodeConfigPropertyType.Position:
        formFields.push(
          <ItemPositionFormField
            key={`${sectionName} Item Position`}
            name={nodeConfigProperty.name}
            node={node!}
            forceNodeUpdate={updateNode}
          />
        );
        break;
      case NodeConfigPropertyType.ViewDataSourceDetails:
        formFields.push(
          <ViewDataSourceDetailsField
            key={`${sectionName}`}
            name={nodeConfigProperty.name}
            node={node!}
            forceNodeUpdate={updateNode}
          />
        );
        break;
      case NodeConfigPropertyType.StandardObject:
        formFields.push(
          <StandardObjectPickerFormField
            key={`${sectionName} StandardObject Picker`}
            name={nodeConfigProperty.name}
            node={node!}
            forceNodeUpdate={updateNode}
            options={nodeConfigProperty.options}
            onChange={
              nodeConfigProperty.schemaSystem?.createIo
                ? () =>
                    createIo({
                      node: node!,
                      schemaSystemProperty: nodeConfigProperty,
                      bannerContext: viewController.bannerContext,
                      plexGetCustomFieldsSchema,
                      plexShowOverlay,
                      reactFlowInstance,
                      updateNodeInternals
                    })
                : () => {}
            }
          />
        );
        break;
    }

    let readableName = nodeConfigProperty.name.replaceAll("_", " ");
    readableName = readableName.charAt(0).toUpperCase() + readableName.slice(1);

    return (
      <Form.Row key={`${sectionName} ${label}`}>
        <div key={`${label} Div`} data-testid={`${nodeConfigProperty.name}Property`}>
          <Form.FieldPair key={label} labelText={nodeConfigProperty.label ?? readableName}>
            {formFields}
          </Form.FieldPair>
        </div>
      </Form.Row>
    );
  };

  return (
    <div>
      <Form
        className="node-properties-form"
        onSubmit={(e: any) => e.preventDefault()}
        style={{ padding: "0px 0px 4px" }}
      >
        {generateFormSections()}
      </Form>
    </div>
  );
};
