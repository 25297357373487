import React, { FunctionComponent } from "react";
import { TextFormField } from "./TextFormField";
import { TextAreaFormField } from "./TextAreaFormField";
import { INodeConfigPropertiesProps } from "../NodeTypes/PropertyTypeDefinitions";
import { NumericFormField } from "./NumericFormField";
import { CheckboxFormField } from "./CheckboxFormField";
import { DateFormField } from "./DateFormField";
import { TimeFormField } from "./TimeFormField";
import { DateTimeFormField } from "./DateTimeFormField";
import { getSchemaId } from "../NodeTypes/DataSchemas";

export interface IValueFormFieldProps extends INodeConfigPropertiesProps {
  inputBasisType: "valueType" | "outputType";
}

export const ValueFormField: FunctionComponent<IValueFormFieldProps> = (props) => {
  if (props.inputBasisType === "valueType") {
    if (!props.node?.data.nodeProperties.outputs.result) {
      props.node!.data.nodeProperties.outputs = {
        result: { enabled: true, type: "uuid", schemaId: getSchemaId("uuid"), hideLabel: true }
      };
      props.forceNodeUpdate();
    }

    if (!props.node?.data.nodeProperties.outputs.result) {
      return null;
    }
  }

  const outputType = props.node.data.nodeProperties.outputs.result?.type;

  switch (outputType) {
    case "uuid":
      return (
        <TextFormField
          key={`${props.name} UUID`}
          name={props.name}
          sectionName={props.sectionName}
          label="UUID"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
        />
      );
    case "string":
      if (props.node.data.nodeProperties.type === "nowConstant") {
        const isoDate = new Date().toISOString();
        const value = isoDate.slice(0, isoDate.length - 5);
        props.node!.data.nodeProperties[props.name] = value;
        props.forceNodeUpdate();
        return (
          <label key="Now Constant" style={{ paddingTop: "5px" }}>
            {value}
          </label>
        );
      }
      if (props.node.data.nodeProperties.type === "constantNull") {
        const value = null;
        props.node!.data.nodeProperties[props.name] = value;
        props.forceNodeUpdate();
        return (
          <label key="Null Constant" style={{ paddingTop: "5px" }}>
            {"null"}
          </label>
        );
      }
      return (
        <TextAreaFormField
          key={`${props.name} String`}
          name={props.name}
          sectionName={props.sectionName}
          label="String"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
        />
      );
    case "integer":
      return (
        <NumericFormField
          key={`${props.name} Integer`}
          name={props.name}
          sectionName={props.sectionName}
          label="Integer"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
          numericOptions={{ scale: 0 }}
        />
      );
    case "decimal":
      return (
        <NumericFormField
          key={`${props.name} Decimal`}
          name={props.name}
          sectionName={props.sectionName}
          label="Decimal"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
        />
      );
    case "boolean":
      return (
        <CheckboxFormField
          key={`${props.name} Boolean`}
          name={props.name}
          sectionName={props.sectionName}
          label="Boolean"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
        />
      );
    case "date":
      return (
        <DateFormField
          key={`${props.name} Date`}
          name={props.name}
          sectionName={props.sectionName}
          label="Date"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
        />
      );
    case "time":
      return (
        <TimeFormField
          key={`${props.name} Time`}
          name={props.name}
          sectionName={props.sectionName}
          label="Time"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
          formatOptions="short"
          precision="minutes"
        />
      );
    case "dateTime":
      return (
        <DateTimeFormField
          key={`${props.name} DateTime`}
          name={props.name}
          sectionName={props.sectionName}
          label="DateTime"
          node={props.node}
          forceNodeUpdate={props.forceNodeUpdate}
          formatOptions="short"
          precision="minutes"
        />
      );
    default:
      return <></>;
  }
};
