import React, { FunctionComponent } from "react";
import { NodeProps } from "reactflow";
import { BasicNodeLayout, NodeSize, NodeTypeCategory } from "../../../components/NodeTypes/Base";
import { DataType } from "../../../components/NodeTypes/TypeDefinitions";
import { NodeConfigPropertyType } from "../../FlowDocument/PropertyTypeDefinitions";

const sourceInputName = "source";
const resultOutputName = "result";

globalThis.nodeTypeDefinitions.setDefinition({
  componentName: "ConvertToNumberNode",
  label: "To Number",
  category: NodeTypeCategory.transformations,
  controlOutputs: [],
  dataInputs: [{ name: sourceInputName, hideLabel: true, type: DataType.STRING }],
  dataOutputs: [{ name: resultOutputName, hideLabel: true, type: DataType.INTEGER }],
  nodeConfigProperties: [
    {
      name: "inputSchema",
      label: "Input Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: [sourceInputName], dataTypes: [DataType.STRING, DataType.BOOLEAN] }
    },
    {
      name: "outputSchema",
      label: "Output Type",
      propertyType: NodeConfigPropertyType.DataType,
      options: { dataProperties: [resultOutputName], dataTypes: [DataType.INTEGER, DataType.DECIMAL] }
    }
  ],
  size: NodeSize.Small,
  evaluate: (input: any) => {
    return {
      output: { result: parseFloat(input.source) },
      activeControlHandleIds: []
    };
  }
});

export const ConvertToNumberNode: FunctionComponent<NodeProps> = ({ id, data }) => {
  return <BasicNodeLayout id={id} data={data} />;
};
