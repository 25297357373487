export const convertKeysToLowerCaseNested = (obj: any) => {
  const convertedObject = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const lowerCaseKey = key.toLowerCase();
      const value = obj[key];

      // If the value is an array, recursively convert its keys
      if (Array.isArray(value)) {
        convertedObject[lowerCaseKey] = value.map((item) => convertKeysToLowerCaseNested(item));
      } else {
        convertedObject[lowerCaseKey] = value;
      }
    }
  }

  return convertedObject;
};

export const convertKeysToCamelCaseNested = (obj: any) => {
  const convertedObject = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
      const value = obj[key];

      // If the value is an array, recursively convert its keys
      if (Array.isArray(value)) {
        convertedObject[camelCaseKey] = value.map((item) => convertKeysToCamelCaseNested(item));
      } else {
        convertedObject[camelCaseKey] = value;
      }
    }
  }

  return convertedObject;
};

export const convertKeysToCamelCaseArray = (arr: any) => {
  return arr.map((obj: any) => {
    const convertedObject = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        convertedObject[camelCaseKey] = obj[key];
      }
    }
    return convertedObject;
  });
};

