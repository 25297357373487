import {
  DocumentPrimitiveType,
  DocumentSchemaSource,
  DocumentSchemaType,
  IDocumentListSchema,
  IDocumentObjectSchema,
  IDocumentObjectSchemaProperty,
  IDocumentPrimitiveSchema,
  IDocumentSchema
} from "../FlowDocument/FlowDocumentModel";
import { Node } from "reactflow";
import { BaseInputOutput, DataType, dataTypes, isObjectLike, isListType } from "./TypeDefinitions";
import { getDsApiOutputs } from "./DataSourceApi/DataSourceAPiSpecs";
import { INodeProperty, INodeTypeDefinition } from "./Base/NodeTypeDefinitions";

export interface ISchemaSource {
  sourceSystem: string;
  sourceId: string;
}

export interface IDesignerSchema extends IDocumentSchema {
  source: ISchemaSource;
}

let dataSchemas: IDesignerSchema[] = [];

export const getSchema = (schemaId: string) => {
  return (
    dataSchemas.filter((s: IDocumentSchema) => s.id === schemaId)[0] ??
    (getEmptyObjectDocumentSchema() as unknown as IDesignerSchema)
  );
};

export const getSchemas = () => {
  return dataSchemas;
};

export const setSchemas = (schemas: IDocumentSchema[]) => {
  dataSchemas = schemas.map((documentSchema: IDocumentSchema) => {
    return { ...documentSchema, source: { sourceSystem: "", sourceId: "" } };
  });
};

export const emptyObjectSchemaId = "fd_empty_schema";

export const listOfEmptyObjectSchemaId = "fd_list_of_empty_schema";

export const getEmptySchemaId = (dataType: DataType | string) => {
  switch (dataType) {
    case DataType.OBJECT:
      return emptyObjectSchemaId;
    case DataType.OBJECTLIST:
      return listOfEmptyObjectSchemaId;
    default:
      return emptyObjectSchemaId;
  }
};

export const getEmptySchema = (dataType: DataType | string) => {
  switch (dataType) {
    case DataType.OBJECT:
      return getEmptyObjectDocumentSchema();
    case DataType.OBJECTLIST:
      return getListOfEmptyObjectDocumentSchema();
    default:
      return getEmptyObjectDocumentSchema();
  }
};

export const getEmptyObjectDocumentSchema = (): IDocumentObjectSchema => {
  return {
    id: emptyObjectSchemaId,
    schemaType: DocumentSchemaType.object,
    properties: {}
  } as IDocumentObjectSchema;
};

export const getListOfEmptyObjectDocumentSchema = (): IDocumentListSchema => {
  return {
    id: listOfEmptyObjectSchemaId,
    schemaType: DocumentSchemaType.list,
    listItemSchema: emptyObjectSchemaId
  } as IDocumentListSchema;
};

const getSchemaSource = (designerNode: Node<any>) => {
  const nodeDefinition: INodeTypeDefinition = globalThis.nodeTypeDefinitions.getDefinition(designerNode.type!)!;
  const schemaSourceProperty: INodeProperty = nodeDefinition.nodeConfigProperties.filter(
    (p: INodeProperty) => p.schemaSourceSystem
  )[0]!;
  if (schemaSourceProperty) {
    return {
      sourceSystem: schemaSourceProperty.schemaSourceSystem!,
      sourceId: designerNode.data.nodeProperties[schemaSourceProperty.name]?.toString()
    };
  }

  return null;
};

export const updateSchemaSources = (designerNodes: Node<any>[]) => {
  dataSchemas.forEach((designerSchema: IDesignerSchema) => {
    let sourceSystem = "";
    let sourceId = "";

    designerNodes.forEach((designerNode: Node<any>) => {
      const schemaInInputs =
        Object.keys(designerNode.data.nodeProperties.inputs).filter(
          (inputName: string) => designerNode.data.nodeProperties.inputs[inputName].schemaId === designerSchema.id
        ).length > 0;
      const schemaInOutputs =
        Object.keys(designerNode.data.nodeProperties.outputs).filter(
          (outputName: string) => designerNode.data.nodeProperties.outputs[outputName].schemaId === designerSchema.id
        ).length > 0;
      if (schemaInInputs || schemaInOutputs) {
        const schemaSource = getSchemaSource(designerNode);
        if (schemaSource) {
          sourceSystem = schemaSource.sourceSystem;
          sourceId = schemaSource.sourceId;
        }
      }
    });

    designerSchema.source.sourceSystem = sourceSystem;
    designerSchema.source.sourceId = sourceId;

    setSchemaSourceRecurse(designerSchema, sourceSystem, sourceId);
  });
};

const setSchemaSourceRecurse = (schema: IDesignerSchema, sourceSystem: string, sourceId: string) => {
  if (schema.schemaType === DocumentSchemaType.primitive) {
    return;
  }

  schema.source.sourceSystem = sourceSystem;
  schema.source.sourceId = sourceId;
  if (schema.schemaType === DocumentSchemaType.object) {
    const objectSchema: any = schema;
    Object.keys(objectSchema.properties).forEach((propertyName: string) => {
      setSchemaSourceRecurse(getSchema(objectSchema.properties[propertyName]!.schema)!, sourceSystem, sourceId);
    });
  }
  if (schema.schemaType === DocumentSchemaType.list) {
    const listSchema: IDocumentListSchema = schema as any;
    const itemSchema: IDesignerSchema = getSchema(listSchema.listItemSchema)!;
    if (itemSchema.schemaType === DocumentSchemaType.object) {
      itemSchema.source.sourceSystem = sourceSystem;
      itemSchema.source.sourceId = sourceId;
      const objectSchema: IDocumentObjectSchema = itemSchema as any;
      Object.keys(objectSchema.properties).forEach((propertyName: string) => {
        setSchemaSourceRecurse(getSchema(objectSchema.properties[propertyName]!.schema!)!, sourceSystem, sourceId);
      });
    }
  }
};

export const getDataTypeFromSchema = (schemaId: string) => {
  const schema: IDesignerSchema = getSchema(schemaId)!;
  const isPrimitiveList = dataTypes.indexOf(schemaId.replace("_List", "")) !== -1;

  if (isPrimitiveList) {
    return schemaId.replace("_List", "List");
  }

  switch (schema.schemaType) {
    case DocumentSchemaType.primitive:
      return schemaId;
    case DocumentSchemaType.object:
      return "object";
    case DocumentSchemaType.list:
      return "objectList";
  }
};

export const getPrimitiveSchemas = () => {
  const schemas: IDocumentSchema[] = [];

  Object.keys(DocumentPrimitiveType).forEach((primitiveSchemaId: string) => {
    const primitiveSchema: IDocumentPrimitiveSchema = {
      id: primitiveSchemaId,
      schemaType: DocumentSchemaType.primitive,
      primitiveType: (DocumentPrimitiveType as any)[primitiveSchemaId]
    };
    schemas.push(primitiveSchema);

    const primitiveListSchema: IDocumentListSchema = {
      id: `${primitiveSchemaId}_List`,
      schemaType: DocumentSchemaType.list,
      listItemSchema: <DocumentPrimitiveType>primitiveSchemaId
    };
    schemas.push(primitiveListSchema);
  });

  return schemas;
};

export const getSchemaId = (
  designerDataType: string,
  node: Node<any> | undefined = undefined,
  objectPath: string[] | undefined = undefined
) => {
  const dataType = designerDataType;
  const isPrimitive = Object.keys(DocumentPrimitiveType).indexOf(dataType.replace("List", "")) !== -1;
  const isObject = isObjectLike(dataType as DataType);
  const isList = isListType(dataType as DataType);

  if (isPrimitive) {
    return `${dataType.replace("List", "")}${isList ? "_List" : ""}`;
  }

  if (node && isObject) {
    const propertyObjectPath = objectPath ?? [];
    const propertyPath = Array.isArray(propertyObjectPath) ? propertyObjectPath.join("_") : propertyObjectPath;
    const schemaSource = getSchemaSource(node);
    const schemaId = `${schemaSource?.sourceSystem}_${schemaSource?.sourceId}_${propertyPath}${isList ? "_List" : ""}`;

    return schemaId;
  }

  throw new Error(`Cannot get document schema for ${dataType}.`);
};

export const getChildSchemaIdsRecursive = (
  parentSchemaId: string,
  schemas: IDocumentSchema[],
  outChildSchemaIds: string[]
) => {
  const parentSchema = schemas.find((schema: IDocumentSchema) => schema.id === parentSchemaId);

  if (parentSchema) {
    let objectSchema: IDocumentObjectSchema | undefined;

    if (!outChildSchemaIds.includes(parentSchemaId)) {
      outChildSchemaIds.push(parentSchemaId);
    }

    if (parentSchema.schemaType === DocumentSchemaType.object) {
      objectSchema = parentSchema as IDocumentObjectSchema;
    }
    if (parentSchema.schemaType === DocumentSchemaType.list) {
      const objectSchemaId = (parentSchema as IDocumentListSchema).listItemSchema;
      const listItemSchema = schemas.find((schema: IDocumentSchema) => schema.id === objectSchemaId);
      if (listItemSchema && !outChildSchemaIds.includes(listItemSchema.id)) {
        outChildSchemaIds.push(listItemSchema.id);
      }
      if (listItemSchema?.schemaType === DocumentSchemaType.object) {
        objectSchema = listItemSchema as IDocumentObjectSchema;
      }
    }

    if (objectSchema) {
      Object.keys(objectSchema.properties).forEach((propertyName: string) => {
        const schemaProperty = objectSchema?.properties[propertyName];
        if (schemaProperty) {
          getChildSchemaIdsRecursive(schemaProperty.schema, schemas, outChildSchemaIds);
        }
      });
    }
  }
};

export const addObjectSchema = (
  node: Node<any>,
  objectPath: string[] | undefined = undefined,
  schemaSourceSystem: string,
  schemaSourceId: string
): IDocumentObjectSchema => {
  const schema: IDocumentObjectSchema = getObjectSchema(dataSchemas, node, objectPath);
  const designerSchema = { ...schema, source: { sourceSystem: schemaSourceSystem, sourceId: schemaSourceId } };
  const schemaIndex = dataSchemas.findIndex((s: IDesignerSchema) => s.id === schema.id);
  if (schemaIndex !== -1) {
    dataSchemas[schemaIndex] = designerSchema;
  } else {
    dataSchemas.push(designerSchema);
  }
  return schema;
};

export const getObjectSchema = (
  schemas: IDocumentSchema[],
  node: Node<any>,
  objectPath: string[] | undefined = undefined
): IDocumentObjectSchema => {
  const nodeObjectPath: string[] = objectPath ?? [];
  const schemaSource = getSchemaSource(node);

  let designerOutputs: BaseInputOutput[] = [];

  if (schemaSource) {
    if (schemaSource.sourceSystem === DocumentSchemaSource.plexOpenApi) {
      //designerOutputs = getApiOutputs(category, apiTitle, apiAction, currentTargetDTO, nodeObjectPath);
    } else if (schemaSource.sourceSystem === DocumentSchemaSource.dataSource) {
      const forOutput = nodeObjectPath.indexOf("outputs") !== -1;
      designerOutputs = getDsApiOutputs("Commands", schemaSource.sourceId, !forOutput, forOutput);
    }
  }

  const documentOutputs: { [name: string]: IDocumentObjectSchemaProperty } = {};
  designerOutputs.forEach((o: BaseInputOutput) => {
    const outputSchemaId = getSchemaId(o.type, node);
    documentOutputs[o.name] = {
      schema: outputSchemaId,
      required: o.required
    };

    updateSchemas(schemas, node, o.type, objectPath?.concat([o.name]));
  });

  return {
    id: getSchemaId("object", node, objectPath),
    schemaType: DocumentSchemaType.object,
    properties: documentOutputs
  };
};

export const addObjectListSchema = (
  node: Node<any>,
  objectPath: string[] | undefined = undefined,
  schemaSourceSystem: string,
  schemaSourceId: string
) => {
  const schema: {
    id: string;
    schemaType: DocumentSchemaType;
    listItemSchema: string;
  } = getObjectListSchema(dataSchemas, node, objectPath);
  const designerSchema = { ...schema, source: { sourceSystem: schemaSourceSystem, sourceId: schemaSourceId } };
  const schemaIndex = dataSchemas.findIndex((s: IDesignerSchema) => s.id === schema.id);
  if (schemaIndex !== -1) {
    dataSchemas[schemaIndex] = designerSchema;
  } else {
    dataSchemas.push(designerSchema);
  }
  return schema;
};

export const getObjectListSchema = (
  schemas: IDocumentSchema[],
  node: Node<any>,
  objectPath: string[] | undefined = undefined
) => {
  const schemaId: string = getSchemaId("objectList", node, objectPath);
  const itemDataProperty = "object";
  const itemSchemaId: string = getSchemaId(itemDataProperty, node, objectPath).replace("_List", "");

  updateSchemas(schemas, node, itemDataProperty, objectPath);

  return {
    id: schemaId,
    schemaType: DocumentSchemaType.list,
    listItemSchema: itemSchemaId
  };
};

export const updateSchemas = (
  schemas: IDocumentSchema[],
  designerNode: Node<any>,
  designerDataType: string,
  objectPath: string[] | undefined = undefined
) => {
  const schemaId: string = getSchemaId(designerDataType, designerNode, objectPath);
  if (schemas.filter((s: IDocumentSchema) => s.id === schemaId).length === 0) {
    if (isObjectLike(designerDataType as DataType)) {
      if (isListType(designerDataType as DataType)) {
        schemas.push(getObjectListSchema(schemas, designerNode, objectPath));
      } else {
        schemas.push(getObjectSchema(schemas, designerNode, objectPath));
      }
    }
  }
};
