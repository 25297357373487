import React, { FunctionComponent } from "react";

export const SortLabel: FunctionComponent<any> = (props) => {
  const { data } = props;

  const getSortOrderDescription = () => {
    return data.nodeProperties.orderBy ? `${data.nodeProperties.orderBy.replaceAll("_", " ")}` : "";
  };

  return <div className="plex-fd-expression-label">{getSortOrderDescription()}</div>;
};
