import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { useReactFlow, useViewport, XYPosition } from "reactflow";

export interface INodePopupContainer extends PropsWithChildren {
  selectedNodeId?: string;
  reactflowContainer: DOMRect;
  draggable?: boolean;
  visible?: boolean | undefined;
  initialPosition?: XYPosition;
  currentPosition?: XYPosition;
}

export const NodePopupContainer: FunctionComponent<INodePopupContainer> = ({
  selectedNodeId,
  reactflowContainer,
  draggable,
  visible,
  initialPosition,
  currentPosition,
  children
}) => {
  const reactFlowInstance = useReactFlow();
  const node = reactFlowInstance.getNode(selectedNodeId ?? "");
  const { x, y, zoom } = useViewport();
  const [dragBeginState, setDragBeginState] = useState({ x: 0, y: 0 });
  const [dragOffsetState, setDragOffsetState] = useState({ x: 0, y: 0 });

  if (dragOffsetState.x !== 0 && dragOffsetState.y !== 0 && !visible) {
    dragOffsetState.x = 0;
    dragOffsetState.y = 0;
    setDragOffsetState({ ...dragOffsetState });
  }

  const renderPopup = () => {
    if (!reactflowContainer || (!node && visible === undefined) || visible === false) {
      return <></>;
    }
    let offsetOrigin = { x: 0, y: 0 };

    if (initialPosition) {
      offsetOrigin = { ...initialPosition };
    }

    if (node?.position) {
      offsetOrigin = { ...node?.position };
    }

    if (node) {
      offsetOrigin.x += node.width! + 10;
    }

    if (currentPosition) {
      currentPosition.x = offsetOrigin.x + (draggable ? dragOffsetState.x : 0);
      currentPosition.y = offsetOrigin.y + (draggable ? dragOffsetState.y : 0);
    }

    return (
      <div
        key={`nodePopup${node?.id ?? "global"} Div`}
        className="nodrag"
        style={{
          position: "absolute",
          top: `${reactflowContainer.top - 45}px`,
          left: `${reactflowContainer.left}px`,
          width: `${reactflowContainer.width}px`,
          height: `${reactflowContainer.height}px`,
          pointerEvents: "none",
          overflow: "hidden"
        }}
      >
        <div
          style={{
            transform: `translate(${x}px, ${y}px) scale(${zoom})`,
            position: "absolute",
            top: "0",
            left: "0"
          }}
        >
          <div style={{ position: "absolute", top: "0", left: "0" }}>
            <div
              style={{
                transform: `translate(${offsetOrigin.x + (draggable ? dragOffsetState.x : 0)}px, ${
                  offsetOrigin.y + (draggable ? dragOffsetState.y : 0)
                }px)`,
                pointerEvents: "all"
              }}
              className="nodrag"
              draggable={draggable ? "true" : "false"}
              onDrag={(event: React.DragEvent<HTMLDivElement>) => {
                const beginProjectValue = reactFlowInstance.project({
                  x: dragBeginState.x,
                  y: dragBeginState.y
                });
                const projectValue = reactFlowInstance.project({
                  x: event.clientX,
                  y: event.clientY
                });
                event.preventDefault();
                dragOffsetState.x += projectValue.x - beginProjectValue.x;
                dragOffsetState.y += projectValue.y - beginProjectValue.y;
                setDragOffsetState({
                  x: dragOffsetState.x,
                  y: dragOffsetState.y
                });

                dragBeginState.x = event.clientX;
                dragBeginState.y = event.clientY;
                setDragBeginState({ x: event.clientX, y: event.clientY });
              }}
              onDragStart={(event: React.DragEvent<HTMLDivElement>) => {
                dragBeginState.x = event.clientX;
                dragBeginState.y = event.clientY;
                setDragBeginState({ x: event.clientX, y: event.clientY });
              }}
              onDragEnd={(event: React.DragEvent<HTMLDivElement>) => {
                event.preventDefault();
                return false;
              }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderPopup()}</div>;
};
